import styled from 'styled-components';

export const StyledHeading = styled.h2`
  font-family: 'Centra-No2-Thin';
  text-transform: uppercase;
  color: ${(props) => props.theme.globalColors.light};
  line-height: 2;
  letter-spacing: 0.1em;
  padding: 2rem 0;
  user-select: none;
`;
