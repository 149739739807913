import { memo } from 'react';
import { PortfolioTotal } from 'components/organisms';
import { CoinListHeader, CoinListContainer } from './components';
import { StyledCoinPreviews } from './styles';

const CoinPreviews: React.FC = () => {
  return (
    <StyledCoinPreviews>
      <PortfolioTotal />
      <CoinListHeader />
      <CoinListContainer />
    </StyledCoinPreviews>
  );
};

export const CoinPreviewsMemoized = memo(CoinPreviews);
