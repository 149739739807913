import { useState, useRef, useLayoutEffect, useEffect } from 'react';

/*
 * hook which adds collapse functionality to an element which has a dynamic height. It allows
 * the component to change sizes during its lifetime while the collapse functionality remains working.
 * The only requirement is that the target element (contentRef) has a CSS transition on the 'height' property
 *
 * @param initialOpen - whether the collapsible starts opened or collapsed
 * @param durationInMs - how long the transition runs in milliseconds
 */
export const useCollapse = <T extends HTMLElement>(initialOpen: boolean, durationInMs: number) => {
  const [open, setOpen] = useState(initialOpen);
  const baseHeight = useRef('auto');
  const contentRef = useRef<T>(null);
  const delayRef = useRef<number>(-1);
  const initialized = useRef(false);

  /*
   * Disable the collapse animation on initial render when the item starts closed.
   * This is to prevent it from animating to the closed state on render
   */
  useEffect(() => {
    if (contentRef.current && !initialOpen && !initialized.current) {
      contentRef.current.style.transition = 'height 0s';
      initialized.current = true;
    }
  }, [contentRef, initialOpen]);

  /*
   * if it starts closed we have to get the height of the content on render as we need
   * this to animate to when the item opens, when it is opened the height might
   * change so we get it when the item is actively closed.
   */
  useLayoutEffect(() => {
    if (!initialOpen) baseHeight.current = `${contentRef.current?.scrollHeight || 0}px`;
  }, [contentRef, initialOpen]);

  /*
   * onClose - store the current height and animate to closed
   * onOpen - animate to stored height and switch to auto after the animation to allow the item to grow
   */
  useEffect(() => {
    clearTimeout(delayRef.current);
    if (!open && contentRef.current) {
      baseHeight.current = `${contentRef.current?.scrollHeight || 0}px`;
      contentRef.current.style.height = baseHeight.current;
      contentRef.current.style.overflow = 'hidden';

      window.requestAnimationFrame(() => {
        if (contentRef.current) {
          contentRef.current.style.height = '0';
        }
      });
    } else if (open) {
      window.requestAnimationFrame(() => {
        if (contentRef.current) {
          if (contentRef.current) contentRef.current.style.maxHeight = 'initial';
          contentRef.current.style.transition = '';
          contentRef.current.style.overflow = '';
          contentRef.current.style.height = baseHeight.current;
        }

        delayRef.current = window.setTimeout(() => {
          if (contentRef.current) contentRef.current.style.height = 'auto';
        }, durationInMs);
      });
    }
  }, [open, durationInMs]);

  return { open, setOpen, contentRef };
};
