import { useRecoilValue } from 'recoil';
import { settingsDarkmode } from 'app/global/recoil/atoms';
import { ThemeProvider as Provider } from 'styled-components';
import { GlobalStyle, darkTheme, lightTheme } from 'app/global/styles';

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  // Shared State - Retrieve active theme segment
  const settingsDarkmodeValue = useRecoilValue(settingsDarkmode);

  let theme;

  // Switch statement to set the correct theme for the provider
  switch (settingsDarkmodeValue) {
    case true:
      theme = darkTheme;
      break;
    case false:
      theme = lightTheme;
      break;
    default:
      theme = darkTheme;
  }

  return (
    <Provider theme={theme}>
      <GlobalStyle />
      {children}
    </Provider>
  );
};
