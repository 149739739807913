import styled from 'styled-components';

export const StyledContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.span`
  margin-left: 0.5rem;
  color: ${(props) => props.theme.globalColors.light};
  user-select: none;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

export const Option = styled.div<{ isSelected?: boolean }>`
  width: 1rem;
  height: 1rem;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.isSelected ? props.theme.globalColors.solidGreen : props.theme.globalColors.fadedPurple};
  background-color: ${(props) =>
    props.isSelected ? props.theme.globalColors.solidGreen : props.theme.globalColors.trans};
  border-radius: 0.125rem;
`;
