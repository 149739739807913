import styled from 'styled-components';
import { DataDisplayWrapper } from 'components/atoms';

export const StyledDataDisplaySentiment = styled(DataDisplayWrapper)`
  margin-top: 1rem;
  min-height: 10rem;
`;

export const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const BarPositive = styled.div<{ positivePercentage: number }>`
  height: 4px;
  width: ${(props) => props.positivePercentage}%;
  background-color: ${(props) => props.theme.globalColors.solidGreen};
  box-shadow: ${(props) => props.theme.globalColors.fadedGreen} 0px 0px 12px 6px;
`;

export const BarNegative = styled.div<{ negativePercentage: number }>`
  height: 4px;
  width: ${(props) => props.negativePercentage}%;
  background-color: ${(props) => props.theme.globalColors.solidPink};
  box-shadow: ${(props) => props.theme.globalColors.fadedGreen} 0px 0px 12px 6px;
`;
