import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { orderBy } from 'lodash';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useCoinsHoldingList } from 'app/global/graphql/hooks/useCoinsHoldingList';
// import { useSearchCoin } from 'app/global/graphql/hooks/useSearchCoin';
import { useFindPortfolioByID } from 'app/global/graphql/hooks/useFindPortfolioByID';
import { interfaceEditPortfolio, settingsUserCurrency } from 'app/global/recoil/atoms';
import { CoinPreviewEdit } from 'components/organisms';
import { QueryResult } from 'components/molecules';
import { Droppable, Draggable } from 'components/atoms';
import { StyledOverlayContainer, StyledHeading } from './styles';

export const EditPortfolioOverlay: React.FC = () => {
  // RECOIL - Shared State Management
  const interfaceEditPortfolioValue = useRecoilValue(interfaceEditPortfolio);
  const settingsUserCurrencyState = useRecoilValue(settingsUserCurrency);

  // APOLLO QUERY - Find Portfolio By ID
  const { loading: portfolioLoading, error: portfolioError, data: portfolioData } = useFindPortfolioByID(
    '323696982820389452',
  );

  // TODO: Continue with Search
  // APOLLO QUERY - Search Coins By Query
  // const { loading: searchCoinLoading, error: searchCoinLoadingError, data: searchCoinLoadingData } = useSearchCoin(
  //   'Bitcoin',
  // );

  const portfolioItems = orderBy(portfolioData?.findPortfolioByID?.portfolioItems, ['id'], ['asc']);
  const portfolioItemsAsString = Array.prototype.map.call(portfolioItems, (coin) => coin.id).toString();

  // APOLLO QUERY (COIN GHECKO) - Coins Holding List
  const { loading, error, data } = useCoinsHoldingList(settingsUserCurrencyState, portfolioItemsAsString, 0);

  // USE STATE - Local State
  const [orderedPortfolioItems, setOrderedPortfolioItems] = useState(data?.getCoinsMarket || []);

  const onDragEnd = (result: DropResult) => {
    // Check for changes that would make no effect on order
    if (!result.destination) return;

    setOrderedPortfolioItems((previousOrder) => {
      const nextOrder = Array.from(previousOrder);
      const [removed] = nextOrder.splice(result.source.index, 1);

      if (result.destination) {
        nextOrder.splice(result.destination.index, 0, removed);
      }

      return nextOrder;
    });
  };

  useEffect(() => {
    if (data?.getCoinsMarket) {
      setOrderedPortfolioItems(data?.getCoinsMarket);
    }
  }, [data?.getCoinsMarket, setOrderedPortfolioItems]);

  return (
    <QueryResult
      error={error || portfolioError}
      loading={loading || portfolioLoading}
      loadingComponent={<>loading</>}
      data={data?.getCoinsMarket}
    >
      <StyledOverlayContainer isActive={interfaceEditPortfolioValue}>
        <StyledHeading title="Edit Portfolio" />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'portfolio-list'}>
            {orderedPortfolioItems?.map((coin: any, index: number) => (
              <Draggable key={coin.id} index={index} draggableId={coin.id}>
                <CoinPreviewEdit
                  id={coin.id}
                  holding={coin.holding}
                  image={coin.image}
                  name={coin.name}
                  symbol={coin.symbol}
                />
              </Draggable>
            ))}
          </Droppable>
        </DragDropContext>
      </StyledOverlayContainer>
    </QueryResult>
  );
};
