import styled from 'styled-components';

export const StyledDataDisplayGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  > div {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
