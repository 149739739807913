import styled from 'styled-components';

export const StyledUserSettingsButton = styled.button<{ interfaceSettingsState: boolean }>`
  height: 2.5rem;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  border-radius: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;

  svg rect {
    fill: ${(props) => props.theme.globalColors.solidPurple};
  }
`;
