import { useRecoilValue } from 'recoil';
import { interfaceFocus } from 'app/global/recoil/atoms';
import { StyledFooter, Wrapper, Start, End } from './styles';

export const Footer: React.FC = () => {
  // Shared State
  const interfaceFocusState = useRecoilValue(interfaceFocus);

  return (
    <StyledFooter isZonedOut={interfaceFocusState}>
      <Wrapper>
        <Start></Start>
        <End></End>
      </Wrapper>
    </StyledFooter>
  );
};
