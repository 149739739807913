import { gql } from '@apollo/client';

export const GET_COINS_MARKET = gql`
  query GetCoinsMarket(
    $getCoinsMarketVsCurrency: String!
    $getCoinsMarketIds: String!
    $getCoinsMarketSparkline: Boolean!
  ) {
    getCoinsMarket(
      vs_currency: $getCoinsMarketVsCurrency
      ids: $getCoinsMarketIds
      sparkline: $getCoinsMarketSparkline
    ) {
      id
      symbol
      name
      image
      current_price
      market_cap
      market_cap_rank
      fully_diluted_valuation
      total_volume
      high_24h
      low_24h
      price_change_24h
      price_change_percentage_24h
      market_cap_change_24h
      market_cap_change_percentage_24h
      circulating_supply
      total_supply
      max_supply
      ath
      ath_change_percentage
      ath_date
      atl
      atl_change_percentage
      atl_date
      roi {
        times
        currency
        percentage
      }
      last_updated
      sparkline_in_7d {
        price
      }
    }
  }
`;
