import { StyledDataValue, Label, Value, LinkValue } from './styles';
import { convertDateToFormattedDate } from 'app/global/utils/convertDateToFormattedDate';
import { convertNumberToPercentage } from 'app/global/utils/convertNumberToPercentage';
import { convertBigNumberToUserNumber } from 'app/global/utils/convertBigNumberToUserNumber';

export const DataValue: React.FC<DataValueProps> = ({ label, value, dataType }) => {
  // Handle - Data value of type date
  if (dataType === DateValueType.Date && typeof value === 'string') {
    const dateConverted = convertDateToFormattedDate(value, 'MMMM Do YYYY');

    return (
      <DataValueWrapper label={label}>
        <Value>{dateConverted}</Value>
      </DataValueWrapper>
    );
  }

  // Handle - Data value of type balance percentage
  if (dataType === DateValueType.BalancePercentage && typeof value === 'number') {
    const numberConverted = convertNumberToPercentage(value);

    return (
      <DataValueWrapper label={label}>
        <Value>{numberConverted}</Value>
      </DataValueWrapper>
    );
  }

  // Handle - Data value of type big number
  if (dataType === DateValueType.BigNumber && typeof value === 'number') {
    const numberConverted = convertBigNumberToUserNumber(value);

    return (
      <DataValueWrapper label={label}>
        <Value>{numberConverted}</Value>
      </DataValueWrapper>
    );
  }

  // Handle - Data value of type external link
  if (dataType === DateValueType.ExternalLink && typeof value === 'string') {
    if (value === '') {
      return <></>;
    }

    return (
      <DataValueWrapper label={label}>
        <LinkValue href={value} target="_blank">
          {value}
        </LinkValue>
      </DataValueWrapper>
    );
  }

  return (
    <DataValueWrapper label={label}>
      {/* Return value - Undefined or null */}
      {value === undefined || (value === null && <Value>-</Value>)}
      {/* Return value - DataType: Undefined */}
      {value !== undefined && value !== null && dataType === undefined && <Value>{value}</Value>}
    </DataValueWrapper>
  );
};

// Base component
export const DataValueWrapper: React.FC<DataValueWrapperProps> = ({ label, children }) => {
  return (
    <StyledDataValue>
      <Label>{label}</Label>
      {children}
    </StyledDataValue>
  );
};

interface DataValueWrapperProps {
  label: string;
  children: React.ReactNode | React.ReactNode[];
}
interface DataValueProps {
  label: string;
  value: string | number | boolean | undefined | null;
  dataType?: DateValueType;
}

export enum DateValueType {
  Date = 'DATE',
  BalancePercentage = 'BALANCE_PERCENTAGE',
  BigNumber = 'BIG_NUMBER',
  ExternalLink = 'EXTERNAL_LINK',
}
