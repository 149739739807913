import styled from 'styled-components';
import { ProfileSizeType } from 'components/atoms';

export const Picture = styled.img`
  border-radius: ${(props) => props.theme.borderRadius.roundedFull};
  object-fit: contain;
`;

export const PictureLoading = styled.div`
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.globalColors.solidPurple};
`;

export const Container = styled.button<{ size: ProfileSizeType; balance: number }>`
  display: flex;
  align-item: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius.roundedFull};
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  border: 2px solid ${({ theme }) => theme.globalColors.solidPurple};
  border-color: ${(props) => props.balance === 1 && props.theme.globalColors.solidGreen};
  border-color: ${(props) => props.balance === -1 && props.theme.globalColors.solidPink};

  ${(props) =>
    props.size === ProfileSizeType.Small &&
    `
    min-height: 2.5rem;
    min-width: 2.5rem;
    padding: 0.25rem;

    ${Picture} {
        height: 1.75rem;
        width: 1.75rem;
    }
  `}

  ${(props) =>
    props.size === ProfileSizeType.Medium &&
    `
    min-height: 4rem;
    min-width: 4rem;
    padding: 0.6rem;

    ${Picture} {
        height: 2.5rem;
        width: 2.5rem;
    }
  `}

  ${(props) =>
    props.size === ProfileSizeType.Large &&
    `
    width: 6rem;
    height: 6rem;
    min-height: 6rem;
    min-width: 6rem;
    padding: 1rem;


    ${Picture} {
      height: 4rem;
      width: 4rem;
    }
  `}
`;
