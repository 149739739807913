import styled, { css } from 'styled-components';
import { SelectArrow } from 'assets/icons/SelectArrow';

const Option = styled.li<{ selected: boolean }>``;

export const Options = styled.ul`
  z-index: ${(props) => props.theme.zIndex.z100};
  position: absolute;
  max-height: 20rem;
  width: 20rem;
  top: 3.5rem;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
  border-radius: 0.25rem;

  &:hover ${Option} {
    background-color: transparent;
  }

  &:hover ${Option}:hover {
    background-color: ${(props) => props.theme.globalColors.deeperPurple};
  }
`;

export const EmptyOption = styled.div`
  margin: 0.5rem;
`;

export const InputError = styled.p`
  color: red;
  margin: 4px;
  font-size: 0.8rem;
`;

export const ArrowIcon = styled(SelectArrow)`
  height: 0.75rem;
  padding-right: 0.5rem;
  transition: transform 0.2s;
  fill: ${(props) => props.theme.globalColors.solidPurple};
  transition: fill 200ms;
`;

export const PlaceholderBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Placeholder = styled.span<{ atTop?: boolean; disabled?: boolean }>`
  display: none;
  position: absolute;
  left: 0.5rem;
  color: ${(props) => props.theme.globalColors.fadedPurple};
  font-size: ${({ atTop }) => (atTop ? '12px' : '13px')};
  user-select: none;
  padding: 0 0.5rem;
  transform: ${({ atTop }) => (atTop ? 'translateY(-10px)' : 'translateY(12px)')};
  transition: transform 0.1s, font-size 0.1s;

  & > ${PlaceholderBackground} {
    background-color: ${({ disabled }) =>
      disabled ? (props) => props.theme.globalColors.fadedPurple : (props) => props.theme.globalColors.deepPurple};
  }
`;

export const InputWrapper = styled.label<{ active: boolean }>`
  position: relative;
  display: flex;
  height: 2.5rem;
  line-height: 1.1875em;

  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.globalColors.deepPurple};

  &:focus-within ${ArrowIcon} {
    transform: ${({ active }) => (active ? 'scaleY(-1)' : null)};
  }

  &:focus-within ${Placeholder} {
    display: flex;
    font-size: 12px;
    transform: translateY(-10px);
  }

  &:hover ${Placeholder} {
    display: flex;
  }

  &:hover ${ArrowIcon} {
    fill: ${(props) => props.theme.globalColors.purple};
  }
`;

export const InputField = styled.input<{ disabled?: boolean; readOnly?: boolean }>`
  width: 100%;
  font-family: 'Centra-No2-Regular';
  text-transform: uppercase;
  color: ${(props) => props.theme.globalColors.solidPurple};
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  letter-spacing: 0.1em;
  text-overflow: ellipsis;
  user-select: none;
  background-color: transparent;
  outline: 0;
  border: 0;
  padding: 3px 15px;
  transition: color 200ms;
  cursor: ${({ disabled, readOnly }) => {
    if (!disabled) return readOnly ? 'pointer' : 'text';
    return 'default';
  }};

  &:hover {
    color: ${(props) => props.theme.globalColors.purple};
  }
`;

export const Prefix = styled.div``;
export const Postfix = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
`;

// Main
export const Container = styled.div<{ disabled?: boolean; hasError: boolean }>`
  position: relative;

  & > ${InputWrapper} {
    border-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.23)' : null)};
    background-color: ${({ disabled }) => (disabled ? 'gray' : undefined)};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      & ${InputWrapper} {
        border-color: 'red';
      }
    `}
`;
