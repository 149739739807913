import { StyledButton, Prefix, Postfix } from './styles';

export const Button: React.FC<ButtonProps> = ({ title, className, onClickHandler, prefix, postfix }) => {
  return (
    <StyledButton onClick={onClickHandler} className={className}>
      {prefix && <Prefix>{prefix}</Prefix>}
      {title}
      {postfix && <Postfix>{postfix}</Postfix>}
    </StyledButton>
  );
};

interface ButtonProps {
  title: string;
  className?: string;
  onClickHandler?: () => void;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
}
