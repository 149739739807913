import { memo } from 'react';
import { StyledHeading } from './styles';

const Heading: React.FC<HeadingProps> = ({ title, className }) => {
  return <StyledHeading className={className}>{title}</StyledHeading>;
};

interface HeadingProps {
  title: string;
  className?: string;
}

export const HeadingMemoized = memo(Heading);
