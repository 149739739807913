import { Legend } from 'components/molecules';
import { StyledRangeDaily, Wrapper, Label } from './styles';

export const RangeContainer: React.FC<RangeContainerProps> = ({ label, children, signifiers }) => {
  return (
    <StyledRangeDaily>
      <Label title={label} />
      <Wrapper>{children}</Wrapper>
      <Legend signifiers={signifiers} />
    </StyledRangeDaily>
  );
};

interface RangeContainerProps {
  label: string;
  children: React.ReactNode | React.ReactNode[];
  signifiers: React.ReactNode | React.ReactNode[];
}
