import { useQuery } from '@apollo/client';
import { QueryResult, CodePreview } from 'components/molecules';
import { GET_COINS_MARKET } from 'app/global/graphql/getCoinsMarket';
import { GetCoinsMarketQuery, GetCoinsMarketQueryVariables } from 'generated/graphql';
import { useRecoilValue } from 'recoil';
import { settingsUserCurrency } from 'app/global/recoil/atoms';

interface CoinsMarketProps {
  id: string;
}

export const CoinsMarketData: React.FC<CoinsMarketProps> = ({ id }) => {
  // Shared state management
  const settingsUserCurrencyState = useRecoilValue(settingsUserCurrency);

  const { loading, error, data } = useQuery<GetCoinsMarketQuery, GetCoinsMarketQueryVariables>(GET_COINS_MARKET, {
    variables: {
      getCoinsMarketVsCurrency: settingsUserCurrencyState,
      getCoinsMarketIds: id,
      getCoinsMarketSparkline: true,
    },
  });

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <CodePreview title={'Coins Market'} data={data} />
    </QueryResult>
  );
};
