import { gql } from '@apollo/client';

export const FIND_PORTFOLIO_BY_ID = gql`
  query findPortfolioByID($id: ID!) {
    findPortfolioByID(id: $id) {
      name
      portfolioItems {
        id
        holding
      }
      _id
      _ts
    }
  }
`;
