import styled from 'styled-components';

export const StyledCoinHolding = styled.div`
  display: flex;
  flex-direction: column;
  width: 6rem;
  text-align: center;
  line-height: 1.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  background-color: ${(props) => props.theme.globalColors.fadedPurple};
`;

export const Label = styled.span`
  color: hsl(227, 77%, 10%);
  text-transform: uppercase;
  font-family: 'Centra-No2-Bold';
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  letter-spacing: 0.1em;
  user-select: none;
`;

export const Holding = styled.span`
  color: hsl(0, 0%, 100%);
  padding-bottom: 0.25rem;
  font-family: 'Centra-No2-Bold';
`;
