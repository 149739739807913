import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import {
  interfaceSettings,
  settingsUserCurrency,
  settingsPostiveVibesOnly,
  settingsDarkmode,
  settingsSoundEffects,
  settingsHighTimes,
} from 'app/global/recoil/atoms';
import { notifySettingChange } from 'app/global/utils/toasts';
import { useTranslation } from 'app/global/hooks';
import { UserLanguageSelect } from 'components/organisms';
import { Notification, Segment } from 'components/atoms';
import { UserCurrencyEnums } from 'app/global/constants/userCurrencyEnums';
import { UserSetting, SettingType } from '..';
import {
  StyledOverlayContainer,
  StyledHeadingSmall,
  StyledHeading,
  SegmentControllerPriceCurrency,
  ButtonLogout,
} from './styles';

export const UserSettingsContainer: React.FC = () => {
  // Translation
  const { t } = useTranslation();

  // Shared state management - interface
  const interfaceSettingsState = useRecoilValue(interfaceSettings);

  // Shared state management - settings
  const [settingsPostiveVibesOnlyState, setSettingsPostiveVibesOnlyState] = useRecoilState(settingsPostiveVibesOnly);
  const [settingsDarkmodeState, setSettingsDarkmodeState] = useRecoilState(settingsDarkmode);
  const [settingsSoundEffectsState, setSettingsSoundEffectsState] = useRecoilState(settingsSoundEffects);
  const [settingsHighTimesState, setSettingsHighTimesState] = useRecoilState(settingsHighTimes);
  const [settingsUserCurrencyState, setSettingsUserCurrencyState] = useRecoilState(settingsUserCurrency);

  // TODO: TRANSLATIONS
  // Handle settings
  const handleInteractionPVO = (title: string) => {
    setSettingsPostiveVibesOnlyState(!settingsPostiveVibesOnlyState);
    notifySettingChange(
      <Notification label={title} value={`turned ${settingsPostiveVibesOnlyState ? 'off' : 'on'}`} />,
    );
  };

  const handleInteractionDarkmode = (title: string) => {
    setSettingsDarkmodeState(!settingsDarkmodeState);
    notifySettingChange(<Notification label={title} value={`turned ${settingsDarkmodeState ? 'off' : 'on'}`} />);
  };

  const handleInteractionSoundEffects = (title: string) => {
    setSettingsSoundEffectsState(!settingsSoundEffectsState);
    notifySettingChange(<Notification label={title} value={`turned ${settingsSoundEffectsState ? 'off' : 'on'}`} />);
  };

  const handleInteractionHighTimes = (title: string) => {
    setSettingsHighTimesState(!settingsHighTimesState);
    notifySettingChange(<Notification label={title} value={`turned ${settingsHighTimesState ? 'off' : 'on'}`} />);
  };

  // Handle Interaction
  const handleInteractionPriceCurrencySegment = useCallback(
    (userCurrency: UserCurrencyEnums) => {
      setSettingsUserCurrencyState(userCurrency);
    },
    [setSettingsUserCurrencyState],
  );

  // Handle - Logout Interaction
  const { logout } = useAuth0();

  return (
    <StyledOverlayContainer isActive={interfaceSettingsState}>
      <StyledHeading title={t('settings')} />
      <StyledHeadingSmall title={t('setting_category_general')} />
      <UserSetting
        title={t('setting_darkmode.title')}
        description={t('setting_darkmode.description')}
        onInteraction={() => handleInteractionDarkmode('darkmode')}
        value={settingsDarkmodeState}
        type={SettingType.Toggle}
      />
      <UserSetting
        title={t('setting_sound_effects.title')}
        description={t('setting_sound_effects.description')}
        onInteraction={() => handleInteractionSoundEffects('sound effects (SFX)')}
        value={settingsSoundEffectsState}
        type={SettingType.Toggle}
      />
      <UserSetting
        title={t('setting_languages.title')}
        description={t('setting_languages.description')}
        onInteraction={() => handleInteractionSoundEffects('sound effects (SFX)')}
        value={settingsSoundEffectsState}
        type={SettingType.Segment}
      >
        <UserLanguageSelect type={'settings'} languages={['nl', 'en', 'pt']} />
      </UserSetting>
      <StyledHeadingSmall title={t('setting_category_numbers')} />
      <UserSetting
        title={t('setting_display_currency.title')}
        description={t('setting_display_currency.description')}
        onInteraction={() => handleInteractionSoundEffects('sound effects (SFX)')}
        value={settingsSoundEffectsState}
        type={SettingType.Segment}
      >
        <SegmentControllerPriceCurrency>
          <Segment
            activeIndex={settingsUserCurrencyState}
            index={UserCurrencyEnums.US_DOLLAR}
            onInteraction={() => handleInteractionPriceCurrencySegment(UserCurrencyEnums.US_DOLLAR)}
          >
            USD
          </Segment>
          <Segment
            activeIndex={settingsUserCurrencyState}
            index={UserCurrencyEnums.EURO}
            onInteraction={() => handleInteractionPriceCurrencySegment(UserCurrencyEnums.EURO)}
          >
            EUR
          </Segment>
          <Segment
            activeIndex={settingsUserCurrencyState}
            index={UserCurrencyEnums.BITCOIN}
            onInteraction={() => handleInteractionPriceCurrencySegment(UserCurrencyEnums.BITCOIN)}
          >
            BTC
          </Segment>
          <Segment
            activeIndex={settingsUserCurrencyState}
            index={UserCurrencyEnums.SATOSHI}
            onInteraction={() => handleInteractionPriceCurrencySegment(UserCurrencyEnums.SATOSHI)}
          >
            SATS
          </Segment>
        </SegmentControllerPriceCurrency>
      </UserSetting>
      <StyledHeadingSmall title={t('setting_category_notifications')} />
      <UserSetting
        title={t('setting_positive_vibes_only.title')}
        description={t('setting_positive_vibes_only.description')}
        onInteraction={() => handleInteractionPVO('positive vibes only')}
        value={settingsPostiveVibesOnlyState}
        type={SettingType.Toggle}
      />
      <UserSetting
        title={t('setting_high_times.title')}
        description={t('setting_high_times.description')}
        onInteraction={() => handleInteractionHighTimes('high times')}
        value={settingsHighTimesState}
        type={SettingType.Toggle}
      />
      <ButtonLogout
        onClickHandler={() => logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI })}
        title={t('logout')}
      />
    </StyledOverlayContainer>
  );
};
