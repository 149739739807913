interface IconProps {
  title?: string;
  className?: string;
}

export const BrandingLogoLetters = ({ title, className }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 689.75 58.08" className={className}>
    <title>{title}</title>
    <defs>
      <style>{'.prefix__cls-1{fill:#ad9bf6}'}</style>
    </defs>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__Layer_6" data-name="Layer 6">
        <path
          className="prefix__cls-1"
          d="M128.72 1v56h-12.08V1zM190.56 1v56h-12l-26.32-37v37h-11.68V1h12.24l26.08 37.12V1zM285.12 1v56h-11.68V21L257 57h-7.84l-16.4-35.92V57H221V1h14.64l17.6 39 17.6-39zM324.88 1l22.48 56H334.8l-4.72-12h-21.36L304 57h-12.32l22.56-56zM312.4 34.64h14.08l-7.12-18.32zM392.32 19.76a14.76 14.76 0 00-5.84-6.24 17.05 17.05 0 00-8.64-2.16 16.29 16.29 0 00-6.68 1.36 16.88 16.88 0 00-5.4 3.76 17.49 17.49 0 00-3.64 5.64 18.8 18.8 0 000 13.84 17.49 17.49 0 003.64 5.64 17.26 17.26 0 005.44 3.8 16.28 16.28 0 006.72 1.4 15.56 15.56 0 009.6-3 13.29 13.29 0 005.12-7.8H376.4V25.92h29.12v4.48a28.69 28.69 0 01-2.08 10.92A26.27 26.27 0 01388.92 56a29.29 29.29 0 01-31.8-6.4 29.66 29.66 0 01-6.2-9.24A28.15 28.15 0 01348.64 29 27.67 27.67 0 01351 17.76a29.89 29.89 0 016.28-9.24 29.12 29.12 0 019.32-6.24A28.71 28.71 0 01377.92 0a29.89 29.89 0 0115.16 3.84 25.2 25.2 0 0110.12 10.64zM464.4 1v56h-12l-26.32-37v37H414.4V1h12.24l26.08 37.12V1zM488.32 1v56h-12.08V1zM495.68 1h41.6v11.2h-14.72V57h-12.08V12.24h-14.8zM566.4 58.08a25.63 25.63 0 01-9.4-1.64 19.15 19.15 0 01-7-4.68 20.86 20.86 0 01-4.44-7.36 28.1 28.1 0 01-1.56-9.6V1h12.16v33.16c0 3.79.88 6.77 2.64 9s4.2 3.24 7.6 3.24 5.92-1.09 7.68-3.28 2.64-5.17 2.64-9V1h12.16v33.8a28.1 28.1 0 01-1.56 9.6 20.86 20.86 0 01-4.44 7.36 19.24 19.24 0 01-7 4.68 25.67 25.67 0 01-9.48 1.64zM647.44 29a27.27 27.27 0 01-2.24 11 28 28 0 01-6.12 9 28.81 28.81 0 01-9.08 5.88A28.52 28.52 0 01619 57h-19V1h19a28.52 28.52 0 0111 2.2 28.81 28.81 0 019.08 5.92A28 28 0 01645.2 18a27.27 27.27 0 012.24 11zm-12.32 0a16.46 16.46 0 00-10-15.4 16.13 16.13 0 00-6.49-1.32H612v33.48h6.63a16.13 16.13 0 006.49-1.32 16.66 16.66 0 0010-15.4zM656.63 1h33v10.92h-21v11.36h19.12v10.56h-19.12v12.32h21.12V57h-33.12z"
        />
        <path className="prefix__cls-1" d="M128.72 1v56h-12.08V1z" />
        <path
          className="prefix__cls-1"
          d="M128.72 1v56h-12.08V1zM105.16 17.68a28.92 28.92 0 00-6.24-9.2 29.62 29.62 0 00-9.32-6.2 29.84 29.84 0 00-22.88 0 29.62 29.62 0 00-9.32 6.2c-.11.11-.2.23-.31.34a32.42 32.42 0 014.71 7.75 29.89 29.89 0 011.43 4.15 16.55 16.55 0 012.89-4A17.18 17.18 0 0171.48 13a17.09 17.09 0 0113.36 0 16.84 16.84 0 019 9.2 18.18 18.18 0 010 13.6 16.84 16.84 0 01-9 9.2 17.09 17.09 0 01-13.36 0 17.18 17.18 0 01-5.36-3.68 16.55 16.55 0 01-2.89-4 29.89 29.89 0 01-1.43 4.15 32.42 32.42 0 01-4.71 7.75c.11.11.2.23.31.34a29.62 29.62 0 009.32 6.2 29.84 29.84 0 0022.88 0 29.62 29.62 0 009.32-6.2 28.92 28.92 0 006.24-9.2A28.36 28.36 0 00107.44 29a28.36 28.36 0 00-2.28-11.32zM17.24 41.36a17.13 17.13 0 01-3.6-5.52 18.18 18.18 0 010-13.6 17.13 17.13 0 013.6-5.52 16.86 16.86 0 014.12-3.06V1.06a27.64 27.64 0 00-3.52 1.22 29.62 29.62 0 00-9.32 6.2 28.92 28.92 0 00-6.24 9.2A28.36 28.36 0 000 29a28.36 28.36 0 002.28 11.4 28.92 28.92 0 006.24 9.2 29.62 29.62 0 009.32 6.2 27.64 27.64 0 003.52 1.2V44.42a16.86 16.86 0 01-4.12-3.06zM29.28 11.68a16.68 16.68 0 015.74 1V.54a30.54 30.54 0 00-12.08.13v12.24a16.43 16.43 0 016.34-1.23z"
        />
        <path
          className="prefix__cls-1"
          d="M41.32 16.72a17.13 17.13 0 013.6 5.52c.1.24.17.48.26.72h12.76a27.68 27.68 0 00-1.66-5.28A28.92 28.92 0 0050 8.48a29.62 29.62 0 00-9.32-6.2A28.51 28.51 0 0036.6.89v12.47a17.1 17.1 0 014.72 3.36zM29.28 46.4a16.43 16.43 0 01-6.34-1.23v12.24a30.54 30.54 0 0012.06.13V45.4a16.68 16.68 0 01-5.72 1zM45.21 35c-.1.27-.18.54-.29.8a16.64 16.64 0 01-8.32 8.88v12.51a28.51 28.51 0 004.12-1.39A29.62 29.62 0 0050 49.6a28.92 28.92 0 006.24-9.2A27.57 27.57 0 0058 35z"
        />
        <path
          className="prefix__cls-1"
          d="M29.61 38.36A9.36 9.36 0 1136.76 23h6.79a15.19 15.19 0 00-8.17-8.22 15.39 15.39 0 00-12 0A15.21 15.21 0 0015.22 23a15.64 15.64 0 003.27 17 15.48 15.48 0 004.89 3.31 15.39 15.39 0 0012 0 15.27 15.27 0 008.17-8.22h-6.79a9.34 9.34 0 01-7.15 3.27z"
        />
      </g>
    </g>
  </svg>
);
