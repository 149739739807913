export const convertBigNumberToUserNumber = (bigNumber: number) => {
  const ONE_THOUSAND = 1000;
  const ONE_MILLION = 1000000;

  if (bigNumber > ONE_THOUSAND && bigNumber < ONE_MILLION) {
    return `${(bigNumber / ONE_THOUSAND).toFixed(0)} K`;
  }

  if (bigNumber > ONE_MILLION) {
    return `${(bigNumber / ONE_MILLION).toFixed(0)} M`;
  }

  if (bigNumber < -ONE_THOUSAND && bigNumber < -ONE_MILLION) {
    return `${(bigNumber / ONE_THOUSAND).toFixed(0)} K`;
  }

  if (bigNumber < -ONE_MILLION) {
    return `${(bigNumber / ONE_MILLION).toFixed(0)} M`;
  }

  if (bigNumber <= ONE_THOUSAND) {
    return bigNumber;
  } else {
    return `-`;
  }
};
