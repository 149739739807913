import { StyledOverlayContainer } from './styles';

interface OverlayContainerProps {
  children: React.ReactNode | React.ReactNode[];
  isActive: boolean;
  className?: string;
  refs?: any;
}

export const OverlayContainer: React.FC<OverlayContainerProps> = ({ children, isActive, className, refs }) => {
  return (
    <StyledOverlayContainer className={className} isActive={isActive} ref={refs}>
      {children}
    </StyledOverlayContainer>
  );
};
