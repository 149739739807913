import styled from 'styled-components';

export const StyledCoinPriceChange = styled.span<{ balance?: number }>`
  display: block;
  width: 4rem;
  font-family: 'Centra-No2-Bold';
  color: ${(props) => props.theme.globalColors.dark};
  text-align: center;
  line-height: 1.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  user-select: none;
  background-color: ${(props) => props.theme.globalColors.solidPurple};
  background-color: ${(props) => props.balance === 1 && props.theme.globalColors.solidGreen};
  background-color: ${(props) => props.balance === -1 && props.theme.globalColors.solidPink};
  margin-top: 1rem;
`;
