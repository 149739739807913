import styled from 'styled-components';

export const StyledSegment = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  font-family: 'Centra-No2-Regular';
  text-transform: uppercase;
  text-align: center;
  color: ${(props) =>
    props.isActive ? (props) => props.theme.globalColors.deeperPurple : props.theme.globalColors.fadedPurple};
  line-height: 2rem;
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  letter-spacing: 0.1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;

  margin: 0.25rem;
  overflow: hidden;
  transition: all 300ms;
  cursor: pointer;

  background-color: ${(props) =>
    props.isActive ? (props) => props.theme.globalColors.deeperPurple : props.theme.globalColors.trans};
  border-radius: 0.25rem;

  svg {
    display: flex;
  }

  svg rect {
    fill: ${(props) =>
      props.isActive ? (props) => props.theme.globalColors.solidPurple : props.theme.globalColors.light};
  }
`;
