import useSound from 'use-sound';
import soundEffect from 'assets/sounds/minimal-click.wav';
import { SortingDirectionType } from 'app/global/constants/sortingDirectionEnums';
import { UserCurrencyEnums } from 'app/global/constants/userCurrencyEnums';
import { StyledSegment } from './styles';

export const Segment: React.FC<SegmentProps> = ({ className, children, index, activeIndex, onInteraction }) => {
  // Initialize - sound effect
  const [playSoundEffect] = useSound(soundEffect, { volume: 0.5 });

  const handleMouseDown = () => {
    playSoundEffect();
    onInteraction();
  };

  return (
    <StyledSegment className={className} isActive={activeIndex === index} onMouseDown={() => handleMouseDown()}>
      {children}
    </StyledSegment>
  );
};

interface SegmentProps {
  className?: string;
  index: number | SortingDirectionType | UserCurrencyEnums;
  activeIndex: number | SortingDirectionType | UserCurrencyEnums;
  onInteraction: () => void;
  children: React.ReactNode | string;
}
