import { Draggable as DraggableBeautifulDND } from 'react-beautiful-dnd';
import { Container } from './styles';

export const Draggable: React.FC<DraggableProps> = ({ index, draggableId, children }) => {
  return (
    <DraggableBeautifulDND draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? 'article active' : 'article'}
        >
          {children}
        </Container>
      )}
    </DraggableBeautifulDND>
  );
};

interface DraggableProps {
  index: number;
  draggableId: string;
  children: React.ReactNode;
}
