import { DataDisplay, DateDisplayType, DataDisplaySentiment } from 'components/atoms';
import { StyledCoinSentiment, Wrapper } from './styles';

export const CoinSentiment: React.FC<CoinSentimentProps> = ({ positive, negative }) => {
  return (
    <Wrapper>
      <StyledCoinSentiment>
        <DataDisplay label="positive" value={positive} dataType={DateDisplayType.Percentage} />
        <DataDisplay label="negative" value={negative} dataType={DateDisplayType.Percentage} />
      </StyledCoinSentiment>
      <DataDisplaySentiment positive={positive} negative={negative} />
    </Wrapper>
  );
};

interface CoinSentimentProps {
  positive: number | undefined | null;
  negative: number | undefined | null;
}
