import styled from 'styled-components';
import { Heading } from 'components/atoms';
import { OverlayContainer } from 'components/molecules';
import { media } from 'app/global/styles/mediaViewport';

// Overwrite styling

export const StyledOverlayContainer = styled(OverlayContainer)`
  flex-direction: column;
  top: calc(65px + 1rem);
  width: calc(100vw - 2rem);
  height: calc(100vh - 16rem);

  /* Responsive - overwrites */

  ${media.sm} {
    width: 25.5rem;
    height: calc(100vh - 20rem);
  }
`;

export const StyledHeading = styled(Heading)`
  z-index: ${(props) => props.theme.zIndex.z90};
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  padding: 2rem 0 0 0;
`;
