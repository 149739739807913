import { StyledUserSetting, StyledHeadingSmall, Header, Description } from './styles';
import { Toggle } from 'components/atoms';

export const UserSetting: React.FC<SettingProps> = ({ title, description, onInteraction, value, type, children }) => {
  return (
    <StyledUserSetting>
      <Header>
        <StyledHeadingSmall title={title} />
        {type === SettingType.Toggle && (
          <Toggle
            label={value ? 'on' : 'off'}
            isChecked={value}
            onToggle={() => {
              onInteraction();
            }}
          />
        )}
      </Header>
      <Description>{description}</Description>
      {children}
    </StyledUserSetting>
  );
};

interface SettingProps {
  title: string;
  description: string;
  value: boolean;
  type: SettingType;
  children?: React.ReactNode;
  onInteraction: () => void;
}

export enum SettingType {
  Toggle = 'TOGGLE',
  Segment = 'SEGMENT',
}
