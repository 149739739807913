import { useRecoilState, useSetRecoilState } from 'recoil';
import { interfaceFocus, interfaceProfile } from 'app/global/recoil/atoms';
import { Profile, ProfileSizeType } from 'components/atoms';
import { UserProfileOverlayMemoized } from './components';
import { StyledUserProfile } from './styles';

export const UserProfile = ({ user, isLoading }: UserProfileProps) => {
  // Shared state management - interface
  const [interfaceFocusState, setInterfaceFocusState] = useRecoilState(interfaceFocus);
  const setInterfaceProfileState = useSetRecoilState(interfaceProfile);

  const handleInteraction = (newState: boolean) => {
    setInterfaceFocusState(newState);
    setInterfaceProfileState(newState);
  };

  return (
    <StyledUserProfile>
      <Profile
        loading={isLoading}
        picture={user.picture}
        size={ProfileSizeType.Small}
        onInteractionHandler={() => handleInteraction(!interfaceFocusState)}
      />
      <UserProfileOverlayMemoized user={user} />
    </StyledUserProfile>
  );
};

interface UserProfileProps {
  user: any;
  isLoading: boolean;
}
