import { ApolloError } from '@apollo/client';
import { QueryResult } from 'components/molecules';
import { IntroductionStory } from 'components/organisms';
import { Coin } from 'generated/graphql';

export const SectionIntroduction: React.FC<SectionIntroductionProps> = ({ id, data, error, loading }) => {
  return (
    <QueryResult error={error} loading={loading} data={data}>
      <IntroductionStory id={id} description={data?.description?.en} categories={data?.categories} />
    </QueryResult>
  );
};

interface SectionIntroductionProps {
  id: string;
  data: Coin | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}
