import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  background-color: ${(props) => props.theme.globalColors.purple};
  padding-left: 1rem;
  padding-right: 1rem;
  color: ${(props) => props.theme.globalColors.light};
  font-family: 'Centra-No2-Bold';
  font-size: 0.875rem;
  border-radius: 0.25rem;
  user-select: none;
  cursor: pointer;
`;

export const Prefix = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

export const Postfix = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
