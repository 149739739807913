import styled from 'styled-components';
import { media } from 'app/global/styles/mediaViewport';
import { SegmentController } from 'components/atoms';

export const CoinDetailsWrapper = styled.div`
  flex: 1 1 0%;
  height: calc(100vh - 6rem);
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  border-radius: 1rem;
  overflow: scroll;

  /* Hide the scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Responsive - overwrites */

  ${media.xl} {
    height: calc(100vh - 14rem);
    padding: 0 1rem 1rem 1rem;
    margin-left: 0.5rem;
  }
`;

export const CoinDetailsHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
`;

/* Overwriting styles */
export const SegmentControllerDetails = styled(SegmentController)`
  width: 32rem;
`;
