import { gql } from '@apollo/client';

export const GET_COINS_HOLDING_LIST = gql`
  query GetCoinsHoldingList(
    $getCoinsCoinsHoldingListVsCurrency: String!
    $getCoinsCoinsHoldingListIds: String!
    $getCoinsHoldingListSparkline: Boolean!
  ) {
    getCoinsMarket(
      vs_currency: $getCoinsCoinsHoldingListVsCurrency
      ids: $getCoinsCoinsHoldingListIds
      sparkline: $getCoinsHoldingListSparkline
    ) {
      id
      symbol
      name
      image
      current_price
      market_cap_rank
      price_change_24h
      price_change_percentage_24h
      last_updated
      sparkline_in_7d {
        price
      }
    }
  }
`;
