import styled from 'styled-components';

export const StyledHeadingSmall = styled.h2`
  font-family: 'Centra-No2-Regular';
  text-transform: uppercase;
  color: ${(props) => props.theme.globalColors.light};
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  line-height: 1rem;
  letter-spacing: 0.1em;
  margin-bottom: 2rem;
  user-select: none;
`;
