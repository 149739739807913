import styled from 'styled-components';
import { DataDisplayGroup } from 'components/molecules';

export const StyledDataDisplayGroup = styled(DataDisplayGroup)`
  margin-top: 1rem;
`;

export const StyledDataDisplayGroupAlt = styled(DataDisplayGroup)`
  margin-top: 2rem;
`;
