import { StyledDataDisplayWrapper, Label } from './styles';

export const DataDisplayWrapper: React.FC<DataDisplayWrapperProps> = ({ label, children, className }) => {
  return (
    <StyledDataDisplayWrapper className={className}>
      <Label title={label} />
      {children}
    </StyledDataDisplayWrapper>
  );
};

interface DataDisplayWrapperProps {
  label: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}
