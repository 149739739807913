import { StyledContainer, Label, Input, Option } from './styles';

export const Checkbox: React.FC<CheckboxProps> = ({ name, selected, disabled, label, value, onSelect }) => {
  return (
    <StyledContainer>
      <Input
        type="checkbox"
        checked={selected}
        disabled={disabled}
        id={name}
        value={value}
        onChange={() => onSelect(value, !selected)}
      />
      <Option isSelected={selected} />
      <Label>{label}</Label>
    </StyledContainer>
  );
};

interface CheckboxProps {
  name: string;
  disabled?: boolean;
  selected?: boolean;
  label?: string;
  value: string;
  onSelect: (name: string, state: boolean) => void;
}
