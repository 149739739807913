import { Header, Main, Footer, Overlay } from 'components/templates';
import { StyledPageWrapper } from './styles';

interface PageWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <StyledPageWrapper>
      <Overlay />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </StyledPageWrapper>
  );
};
