import { find } from 'lodash';
import parse from 'html-react-parser';
import { SelectPortfolio } from 'app/global/utils/selectPortfolio';
import { Tag } from 'components/atoms';
import { Wrapper, Description, Title, Text, Start, End, Logo, TagGroup, StyledTag } from './styles';

export const IntroductionStory: React.FC<IntroductionStoryrProps> = ({ id, description, categories }) => {
  // Select Portfolio
  const portfolio = SelectPortfolio();

  // Get Object from shared state - holding matching id
  const coin = find(portfolio, ['id', id]);

  return (
    <>
      <Wrapper>
        <Start>
          <Title title={coin?.name} />
          <Description>
            {description ? <Text>{parse(description)}</Text> : <Text>No description found.</Text>}
          </Description>
        </Start>
        <End>
          <Logo src={coin?.image} alt={`logo for ${coin?.name}`} />
          <Tag title={`# ${coin?.market_cap_rank} `} />
        </End>
      </Wrapper>
      <TagGroup>
        {categories?.map((category, i) => (
          <StyledTag title={category} key={id + 'category' + i} />
        ))}
      </TagGroup>
    </>
  );
};

interface IntroductionStoryrProps {
  id: string;
  description: string | undefined | null;
  categories: string[] | undefined | null;
}
