export interface IconProps {
  title?: string;
  className?: string;
}

export const SelectArrow = ({ title, className }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={className}>
    <title>{title}</title>
    <path d="M15,23.18,1.78,13A2,2,0,1,1,4.22,9.85L15,18.14,25.79,9.91a2,2,0,1,1,2.42,3.18Z" />
  </svg>
);
