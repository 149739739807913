import { useUrlQuery } from 'app/global/hooks';
import { orderBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import { settingsUserCurrency, interfaceSortingOption, interfaceSortingDirection } from 'app/global/recoil/atoms';
import { SortingType } from 'app/global/constants/sortingEnums';
import { SortingDirectionType } from 'app/global/constants/sortingDirectionEnums';
import { useCoinsHoldingList } from 'app/global/graphql/hooks/useCoinsHoldingList';
import { useFindPortfolioByID } from 'app/global/graphql/hooks/useFindPortfolioByID';
import { combinePortfolios } from 'app/global/utils/combinePortfolios';
import { CoinPreviewPortfolio } from 'components/organisms';
import { QueryResult } from 'components/molecules';
import { CoinListCointainerSkeleton as Skeleton } from '../';
import { StyledCoinListContainer } from './styles';

export const CoinListContainer: React.FC = () => {
  // RECOIL - Shared State Management
  const settingsUserCurrencyState = useRecoilValue(settingsUserCurrency);
  const interfaceSortingOptionState = useRecoilValue(interfaceSortingOption);
  const interfaceSortingDirectionState = useRecoilValue(interfaceSortingDirection);
  const isDisplayingCoin = useUrlQuery().get('name');

  // APOLLO QUERY (FAUNA) - Find Portfolio By ID
  const { loading: portfolioLoading, error: portfolioError, data: portfolioData } = useFindPortfolioByID(
    '323696982820389452',
  );

  const portfolioItems = portfolioData?.findPortfolioByID?.portfolioItems;
  const portfolioItemsSortedABC = orderBy(portfolioItems, ['id'], ['asc']);
  const portfolioItemsAsString = Array.prototype.map.call(portfolioItemsSortedABC, (coin) => coin.id).toString();

  // APOLLO QUERY (COIN GHECKO) - Coins Holding List
  const { loading, error, data: externalPortfolioData } = useCoinsHoldingList(
    settingsUserCurrencyState,
    portfolioItemsAsString,
    0,
  );

  let sortedData;

  if (externalPortfolioData?.getCoinsMarket && portfolioData) {
    const mergedData = combinePortfolios(
      portfolioData?.findPortfolioByID?.portfolioItems,
      externalPortfolioData?.getCoinsMarket,
    );

    switch (interfaceSortingOptionState + interfaceSortingDirectionState) {
      case SortingType.PriceChange + SortingDirectionType.Descending:
        sortedData = orderBy(mergedData, ['price_change_percentage_24h'], ['desc']);
        break;
      case SortingType.PriceChange + SortingDirectionType.Ascending:
        sortedData = orderBy(mergedData, ['price_change_percentage_24h'], ['asc']);
        break;
      case SortingType.MarketCap + SortingDirectionType.Descending:
        sortedData = orderBy(mergedData, ['market_cap_rank'], ['asc']);
        break;
      case SortingType.MarketCap + SortingDirectionType.Ascending:
        sortedData = orderBy(mergedData, ['market_cap_rank'], ['desc']);
        break;
      case SortingType.Price + SortingDirectionType.Descending:
        sortedData = orderBy(mergedData, ['current_price'], ['desc']);
        break;
      case SortingType.Price + SortingDirectionType.Ascending:
        sortedData = orderBy(mergedData, ['current_price'], ['asc']);
        break;
      case SortingType.CoinName + SortingDirectionType.Descending:
        sortedData = orderBy(mergedData, ['id'], ['desc']);
        break;
      case SortingType.CoinName + SortingDirectionType.Ascending:
        sortedData = orderBy(mergedData, ['id'], ['asc']);
        break;
      default:
        sortedData = mergedData;
    }
  }

  return (
    <QueryResult
      error={error || portfolioError}
      loading={loading || portfolioLoading}
      loadingComponent={<Skeleton />}
      data={sortedData}
    >
      <StyledCoinListContainer>
        {sortedData?.map((coin: any) => (
          <CoinPreviewPortfolio
            key={coin.id}
            id={coin.id}
            holding={coin.holding}
            image={coin.image}
            name={coin.name}
            symbol={coin.symbol}
            currentPrice={coin.current_price}
            priceChangePercentage24H={coin.price_change_percentage_24h}
            isActive={isDisplayingCoin === coin.id}
          />
        ))}
      </StyledCoinListContainer>
    </QueryResult>
  );
};
