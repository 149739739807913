import styled from 'styled-components';

export const Option = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  min-height: 2.5rem;
  background-color: ${({ selected }) => (selected ? (props) => props.theme.globalColors.deeperPurple : null)};
  margin: 0.25rem;
  word-break: break-word;
  cursor: pointer;
  border-radius: 0.25rem;

  font-family: 'Centra-No2-Regular';
  text-transform: uppercase;
  color: ${({ selected }) =>
    selected ? (props) => props.theme.globalColors.solidPurple : (props) => props.theme.globalColors.light};
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  letter-spacing: 0.1em;
  text-overflow: ellipsis;
  user-select: none;

  &:hover {
    color: ${(props) => props.theme.globalColors.purple};
  }
`;

export const Options = styled.ul``;

export const EmptyOption = styled.div``;
