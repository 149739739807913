import { useQuery } from '@apollo/client';
import { QueryResult, CodePreview } from 'components/molecules';
import { GET_PING } from 'app/global/graphql/getPing';
import { GetPingQuery, GetPingQueryVariables } from 'generated/graphql';

export const PingData: React.FC = () => {
  const { loading, error, data } = useQuery<GetPingQuery, GetPingQueryVariables>(GET_PING);

  const inputValues = ['gecko_says'];

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <CodePreview title={'1.Ping'} data={data} inputValues={inputValues} />
    </QueryResult>
  );
};
