import styled from 'styled-components';

export const Container = styled.span`
  display: block;
  width: 4rem;
  height: 1.75rem;
  font-family: 'Centra-No2-Bold';
  color: ${(props) => props.theme.globalColors.dark};
  text-align: center;
  line-height: 1.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  user-select: none;
  background-color: ${(props) => props.theme.globalColors.solidPurple};
`;
