import { useAuth0 } from '@auth0/auth0-react';
// TODO: Work smarter with recoil
// import { useRecoilValue, useRecoilState } from 'recoil';
import { customCoins, defaultCoinsBackUp } from 'assets/data/portfolio';

// Custom Function - Check authentication for matching portfolio
export const SelectPortfolio = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user, isAuthenticated } = useAuth0();

  // Select Portfolio
  let portfolio = defaultCoinsBackUp;

  if (isAuthenticated && user?.name === 'Bart Oosten') {
    portfolio = customCoins;
    return portfolio;
  }

  return portfolio;
};
