import { findExternalCoinData } from 'app/global/utils/findExternalCoinData';

export const combinePortfolios = (coins: any, coinData: any) => {
  const combinedPortfolioV2 = coins.map((coin: any) => {
    const externalCoin = findExternalCoinData(coinData, coin.id);

    const newCoinComposition = {
      ...externalCoin,
      holding: coin.holding,
      totalHolding: externalCoin?.current_price * coin.holding,
    };

    return newCoinComposition;
  });

  return combinedPortfolioV2;
};
