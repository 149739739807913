import styled from 'styled-components';
import { media } from 'app/global/styles/mediaViewport';

export const StyledFooter = styled.footer<{ isZonedOut: boolean }>`
  z-index: ${(props) => props.theme.zIndex.z80};
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 4rem;
  background-color: ${(props) =>
    props.isZonedOut ? props.theme.globalColors.blurryPurple : props.theme.globalColors.deeperPurple};
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);

  ${media.xl} {
    background-color: ${(props) => props.theme.globalColors.deeperPurple};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* Responsive - overwrites */

  ${media.sm} {
    max-width: ${(props) => props.theme.breakpoints.sm}px;
  }

  ${media.md} {
    max-width: ${(props) => props.theme.breakpoints.md}px;
  }

  ${media.lg} {
    max-width: ${(props) => props.theme.breakpoints.lg}px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${media.xl} {
    max-width: ${(props) => props.theme.breakpoints.xl}px;
  }

  ${media.xxl} {
    max-width: ${(props) => props.theme.breakpoints.xxl}px;
  }
`;

export const Start = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const End = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;
