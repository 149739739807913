import { useRecoilValue } from 'recoil';
import { useCoinBasics } from 'app/global/graphql/hooks/useCoinBasics';
import { useCoinsMarket } from 'app/global/graphql/hooks/useCoinsMarket';
import { settingsUserCurrency } from 'app/global/recoil/atoms';
import {
  SectionIntroduction,
  SectionLast24Hours,
  SectionSocial,
  // SectionMarket,
  // SectionBasics,
  // SectionCharts,
} from './components';
import { CoinDetailsWrapper } from './styles';

export const CoinDetails: React.FC<CoinDetailsProps> = ({ coinName }) => {
  // Shared state management
  const settingsUserCurrencyState = useRecoilValue(settingsUserCurrency);

  // Apollo custom hook - Coin basics information
  const { loading: loadingCoinBasics, error: errorCoinBasics, data: dataCoinBasics } = useCoinBasics(coinName);
  const { loading: loadingCoinsMarket, error: errorCoinsMarket, data: dataCoinsMarket } = useCoinsMarket(
    settingsUserCurrencyState,
    coinName,
    0,
  );

  // TODO: Open charts by clicking spark to view details
  // TODO: Turn the rest of market into holdings section
  return (
    <CoinDetailsWrapper>
      <SectionIntroduction
        id={coinName}
        loading={loadingCoinBasics}
        error={errorCoinBasics}
        data={dataCoinBasics?.getCoin}
      />
      <SectionLast24Hours loading={loadingCoinsMarket} error={errorCoinsMarket} data={dataCoinsMarket} />
      <SectionSocial loading={loadingCoinBasics} error={errorCoinBasics} data={dataCoinBasics?.getCoin} />
      {/* <SectionBasics id={coinName} /> */}
      {/* <SectionMarket id={coinName} /> */}
      {/* <SectionCharts id={coinName} /> */}
    </CoinDetailsWrapper>
  );
};

interface CoinDetailsProps {
  coinName: string;
}
