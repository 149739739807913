import styled, { css, keyframes } from 'styled-components';

export const StyledOverlay = styled.div<{ isZonedOut?: boolean }>`
  z-index: ${(props) => props.theme.zIndex.z90};
  display: ${(props) => (props.isZonedOut ? 'flex' : 'none')};
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.globalColors.blurryPurple};

  ${(props) => props.isZonedOut && blurIn}
`;

const blurInAnimation = keyframes`
  from {
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
  }

  to {
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
  }
`;

const blurIn = css`
  animation-name: ${blurInAnimation};
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;
