import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
`;
