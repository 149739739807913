import { gql } from '@apollo/client';

export const FIND_USER_BY_ID = gql`
  query findUserByID($id: ID!) {
    findUserByID(id: $id) {
      name
      userRoles {
        type
        endDate
      }
      _id
      _ts
    }
  }
`;
