import React, { useEffect, useCallback, useState } from 'react';
import { useCollapse } from 'app/global/hooks/useCollapse';
import { timeout } from 'app/global/utils/timeout';
import { Spinner } from 'components/atoms/Spinner';

import {
  Container,
  Header,
  Title,
  AngleContainer,
  Content,
  Centered,
  ContentContainer,
  Actions,
  OpenContent,
  ClosedContent,
  ArrowIcon,
} from './styles';

export const ExpansionPanel: React.FC<ExpansionPanelProps> = ({
  title,
  onClick,
  active,
  initialClosed,
  children,
  error,
  loading,
  closedDisplay,
  noOutline,
}) => {
  // Local State
  const [open, setOpen] = useState(!initialClosed);
  const { open: openContent, setOpen: setOpenContent, contentRef: openRef } = useCollapse<HTMLDivElement>(open, 400);
  const { open: closedContent, setOpen: setClosedContent, contentRef: closedRef } = useCollapse<HTMLDivElement>(
    !open,
    400,
  );

  const toggle = useCallback(
    async (open?: boolean) => {
      if (closedDisplay) {
        if (open) {
          setClosedContent(false);
          await timeout(150);
          setOpenContent(true);
        } else {
          setOpenContent(false);
          await timeout(150);
          setClosedContent(true);
        }
      } else {
        setOpenContent(!!open);
      }
    },
    [setClosedContent, setOpenContent, closedDisplay],
  );

  const handleSelect = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      setOpen(!open);
    }
  }, [onClick, open]);

  // Montoring
  useEffect(() => {
    if (active !== undefined) {
      setOpen(active);
    }
  }, [active, setOpen]);

  useEffect(() => {
    toggle(open);
  }, [open, toggle]);

  return (
    <Container>
      <Header onClick={handleSelect}>
        <Title title={title} />
        <Actions>
          <AngleContainer open={open}>
            <ArrowIcon title="Open" />
          </AngleContainer>
        </Actions>
      </Header>
      <Content open={open || !!closedDisplay} noOutline={noOutline}>
        {loading && (
          <Centered>
            <Spinner scale={0.9} />
          </Centered>
        )}
        {!loading && error && <Centered>{error}</Centered>}
        {!error && !loading && (
          <ContentContainer>
            <OpenContent ref={openRef} open={openContent}>
              {children}
            </OpenContent>
            <ClosedContent ref={closedRef} open={closedContent} onClick={() => setOpen(true)}>
              {closedDisplay}
            </ClosedContent>
          </ContentContainer>
        )}
      </Content>
    </Container>
  );
};

export type ExpansionPanelProps = {
  title?: string;
  initialClosed?: boolean;
  onClick?: () => void;
  active?: boolean;
  error?: string;
  loading?: boolean;
  closedDisplay?: React.ReactElement;
  noOutline?: boolean;
};
