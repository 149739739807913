import styled from 'styled-components';

export const StyledOverlayContainer = styled.div<{ isActive: boolean }>`
  z-index: ${(props) => props.theme.zIndex.z100};
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  position: absolute;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  border-radius: 1rem;
  padding: 0 2rem 2rem 2rem;
  overflow: scroll;

  /* Hide the scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
