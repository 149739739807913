import styled from 'styled-components';
import { HeadingSmall, Tag } from 'components/atoms';

export const Wrapper = styled.div`
  z-index: ${(props) => props.theme.zIndex.z90};
  height: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const Start = styled.div`
  height: 10rem;
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.globalColors.solidPurple};
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
`;

export const End = styled.div`
  height: 10rem;
  flex: 1 1 1;
  background-color: ${(props) => props.theme.globalColors.fadedPurple};
  padding: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;

export const Logo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  padding: 0.25rem;
  border: 2px solid ${(props) => props.theme.globalColors.fadedPurple};
  border-radius: 9999px;
  margin-bottom: 1rem;
`;

export const TagGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

/* Overwriting styles */

export const StyledTag = styled(Tag)`
  width: auto;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: hidden;
`;

export const Title = styled(HeadingSmall)`
  color: ${(props) => props.theme.globalColors.dark};
  line-height: 1.75rem;
  margin-bottom: 0;
  margin-left: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  font-family: 'Centra-No2-Bold';
`;

export const Description = styled.article``;

export const Text = styled.p`
  max-height: 11vh;
  color: ${(props) => props.theme.globalColors.fadedPurple};
  line-height: 1.75rem;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: scroll;

  /* Hide the scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > a {
    color: ${(props) => props.theme.globalColors.light};
  }
`;
