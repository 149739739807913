import { gql } from '@apollo/client';

export const GET_COIN_BASICS = gql`
  query GetCoinBasics($getCoinId: ID!) {
    getCoin(id: $getCoinId) {
      id
      symbol
      name
      asset_platform_id
      platforms {
        ethereum
        solana
      }
      block_time_in_minutes
      hashing_algorithm
      categories
      public_notice
      additional_notices
      description {
        en
      }
      links {
        homepage
        blockchain_site
        official_forum_url
        chat_url
        announcement_url
        twitter_screen_name
        facebook_username
        bitcointalk_thread_identifier
        telegram_channel_identifier
        subreddit_url
        repos_url {
          github
          bitbucket
        }
      }
      image {
        thumb
        small
        large
      }
      country_origin
      genesis_date
      contract_address
      sentiment_votes_up_percentage
      sentiment_votes_down_percentage
    }
  }
`;
