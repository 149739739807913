import { useQuery } from '@apollo/client';
import { GetCoinsMarketQuery, GetCoinsMarketQueryVariables } from 'generated/graphql';
import { GET_COINS_MARKET } from 'app/global/graphql/getCoinsMarket';
import { UserCurrencyEnums } from 'app/global/constants/userCurrencyEnums';

export const useCoinsMarket = (currency: UserCurrencyEnums, ids: string, pollInterval: number) => {
  const { loading, error, data } = useQuery<GetCoinsMarketQuery, GetCoinsMarketQueryVariables>(GET_COINS_MARKET, {
    variables: {
      getCoinsMarketVsCurrency: currency,
      getCoinsMarketIds: ids,
      getCoinsMarketSparkline: true,
    },
    pollInterval,
  });

  return {
    loading,
    error,
    data,
  };
};
