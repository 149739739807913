import { UserSettingsButton, UserSettingsContainer } from './components';
import { StyledUserSettings } from './styles';

export const UserSettings: React.FC = () => {
  return (
    <StyledUserSettings>
      <UserSettingsButton />
      <UserSettingsContainer />
    </StyledUserSettings>
  );
};
