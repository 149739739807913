import { Container, Ring } from './styles';

export const Spinner = ({ scale = 1, color = 'blue', className }: SpinnerProps) => (
  <Container scale={scale} className={className}>
    <Ring color={color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Ring>
  </Container>
);

export interface SpinnerProps {
  scale?: number;
  color?: 'blue' | 'white';
  className?: string;
}
