import styled from 'styled-components';
import { HeadingSmall } from 'components/atoms';

export const StyledUserSetting = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.span`
  display: block;
  min-width: 16rem;
  font-family: 'Centra-No2-Regular';
  color: ${(props) => props.theme.globalColors.fadedPurple};
  font-size: 0.875rem;
  user-select: none;
  line-height: 1.6rem;
`;

// Overwrite style

export const StyledHeadingSmall = styled(HeadingSmall)`
  color: ${(props) => props.theme.globalColors.solidPurple};
  line-height: 3rem;
  margin-bottom: 0;
`;
