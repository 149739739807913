import styled from 'styled-components';

export const StyledButtonPortfolio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1.5rem;
  margin-left: 0;
  margin-top: 1rem;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>💰</text></svg>")
      16 0,
    auto;
`;

export const Header = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;

  font-family: 'Centra-No2-Regular';
  font-family: 'Centra-No2-Bold';
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  letter-spacing: 0.1em;
  color: ${(props) => props.theme.globalColors.dark};

  background-color: ${(props) => props.theme.globalColors.solidGreen};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  user-select: none;
`;

export const Footer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;

  font-family: 'Centra-No2-Bold';
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  letter-spacing: 0.1em;
  color: ${(props) => props.theme.globalColors.light};

  background-color: ${(props) => props.theme.globalColors.solidGreen};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  user-select: none;
`;

export const Amount = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  min-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: 'Centra-No2-Bold';
  font-size: 1.5rem;
  color: ${(props) => props.theme.globalColors.light};
  text-shadow: none;

  background-color: ${(props) => props.theme.globalColors.solidGreen};
  box-shadow: none;
  user-select: none;
`;

export const Currency = styled.span`
  font-family: 'Centra-No2-Thin';
`;
