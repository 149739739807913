import styled from 'styled-components';
import { HeadingSmall } from 'components/atoms';

export const StyledRangeDaily = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

/* Overwriting styles */
export const Label = styled(HeadingSmall)`
  color: ${(props) => props.theme.globalColors.solidPurple};
  line-height: 2.5rem;
  margin: 0 auto 0 auto;
  transform: translateY(1rem);
`;
