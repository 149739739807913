import { Helmet } from 'react-helmet';
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider as Auth0Provider } from 'app/global/context/Auth0Provider';
import { AuthTokenProvider } from 'app/global/context/AuthTokenContext';
import { ApolloProvider } from 'app/global/context/ApolloProvider';
import { ThemeProvider } from 'app/global/context/ThemeProvider';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { PageWrapper } from 'components/templates/';
import { ErrorBoundary } from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';

// Routing - defining top-level routes
const Portfolio = lazy(() => import('./components/pages/Portfolio'));
const DataSources = lazy(() => import('./components/pages/DataSources'));
const NoMatch = lazy(() => import('./components/pages/NoMatch'));
const ErrorView = lazy(() => import('./components/pages/ErrorView'));

function App() {
  return (
    <ErrorBoundary fallback={<ErrorView />}>
      <Auth0Provider>
        <AuthTokenProvider>
          <RecoilRoot>
            <ApolloProvider>
              <ThemeProvider>
                <ToastContainer />
                <Router>
                  <PageWrapper>
                    <Helmet>
                      <title>Coin Magnitude</title>
                    </Helmet>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Switch>
                        <Route exact path="/" component={Portfolio} />
                        <Route exact path="/data-sources" component={DataSources} />
                        <Route exact path="/error" component={ErrorView} />
                        <Route component={NoMatch} />
                      </Switch>
                    </Suspense>
                  </PageWrapper>
                </Router>
              </ThemeProvider>
            </ApolloProvider>
          </RecoilRoot>
        </AuthTokenProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

export default App;
