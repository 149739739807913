const restObject = {
  current_price: 0,
  image: '',
  last_updated: '',
  market_cap_rank: 0,
  name: '',
  price_change_24h: 0,
  price_change_percentage_24h: 0,
  symbol: '',
  sparkline_in_7d: {
    price: [],
  },
};

export const defaultCoins = [
  {
    id: 'bitcoin',
    holding: 1,
  },
  {
    id: 'ethereum',
    holding: 1,
  },
  {
    id: 'tether',
    holding: 1,
  },
  {
    id: 'binancecoin',
    holding: 1,
  },
  {
    id: 'usd-coin',
    holding: 1,
  },
  {
    id: 'cardano',
    holding: 1,
  },
  {
    id: 'ripple',
    holding: 1,
  },
  {
    id: 'solana',
    holding: 1,
  },
  {
    id: 'terra-luna',
    holding: 1,
  },
  {
    id: 'polkadot',
    holding: 1,
  },
];

export const defaultCoinsBackUp = [
  {
    id: 'bitcoin',
    holding: 1,
    ...restObject,
  },
  {
    id: 'ethereum',
    holding: 1,
    ...restObject,
  },
  {
    id: 'tether',
    holding: 1,
    ...restObject,
  },
  {
    id: 'binancecoin',
    holding: 1,
    ...restObject,
  },
  {
    id: 'usd-coin',
    holding: 1,
    ...restObject,
  },
  {
    id: 'cardano',
    holding: 1,
    ...restObject,
  },
  {
    id: 'ripple',
    holding: 1,
    ...restObject,
  },
  {
    id: 'solana',
    holding: 1,
    ...restObject,
  },
  {
    id: 'terra-luna',
    holding: 1,
    ...restObject,
  },
  {
    id: 'polkadot',
    holding: 1,
    ...restObject,
  },
];

export const customCoins = [
  // Wallet - Phoenix + Exchange
  {
    id: 'bitcoin',
    holding: 0.00542134,
    ...restObject,
  },
  // Exchange - Coinmetro
  {
    id: 'encrypgen',
    holding: 1999,
    ...restObject,
  },
  {
    id: 'qredo',
    holding: 10.35,
    ...restObject,
  },
  // FTX Wallet
  {
    id: 'solana',
    holding: 3.0318,
    ...restObject,
  },
  {
    id: 'raydium',
    holding: 14.0157,
    ...restObject,
  },
  {
    id: 'badger-dao',
    holding: 4.3877,
    ...restObject,
  },
  {
    id: 'spell-token',
    holding: 3683.3403,
    ...restObject,
  },
  {
    id: 'blocto-token',
    holding: 28.6917,
    ...restObject,
  },
  {
    id: 'kin',
    holding: 510820,
    ...restObject,
  },
  {
    id: 'maker',
    holding: 0.0151008,
    ...restObject,
  },
  {
    id: 'fantom',
    holding: 15.6151,
    ...restObject,
  },
  {
    id: 'reef-finance',
    holding: 1540.4507,
    ...restObject,
  },
  {
    id: 'celsius-degree-token',
    holding: 5.0295,
    ...restObject,
  },
  {
    id: 'hxro',
    holding: 51.4536,
    ...restObject,
  },
  {
    id: 'serum',
    holding: 3.0058,
    ...restObject,
  },
  {
    id: 'perpetual-protocol',
    holding: 1.1334,
    ...restObject,
  },
  {
    id: 'dydx',
    holding: 2.3517,
    ...restObject,
  },
  {
    id: 'step-finance',
    holding: 20.0735,
    ...restObject,
  },
  {
    id: 'the-graph',
    holding: 15.0924,
    ...restObject,
  },
  {
    id: 'star-atlas',
    holding: 162.4372,
    ...restObject,
  },
  {
    id: 'axie-infinity',
    holding: 0.1005416,
    ...restObject,
  },
  {
    id: 'omisego',
    holding: 0.93956263,
    ...restObject,
  },
  {
    id: 'smooth-love-potion',
    holding: 160.6816,
    ...restObject,
  },
  {
    id: 'gods-unchained',
    holding: 14.5276,
    ...restObject,
  },
  // Phantom Wallet
  {
    id: 'mercurial',
    holding: 75.51698,
    ...restObject,
  },
  {
    id: 'solfarm',
    holding: 1.38613,
    ...restObject,
  },
  {
    id: 'synthetify-token',
    holding: 23.72251,
    ...restObject,
  },
  {
    id: 'aleph',
    holding: 19.87976,
    ...restObject,
  },
  {
    id: 'swipe',
    holding: 3.9,
    ...restObject,
  },
  // Meta Mask Wallet
  {
    id: 'feg-token',
    holding: 75685631447.89166,
    ...restObject,
  },
  {
    id: 'ethereum',
    holding: 0.0615,
    ...restObject,
  },
  {
    id: 'amp-token',
    holding: 3000,
    ...restObject,
  },
  {
    id: 'swapdex',
    holding: 1769.022,
    ...restObject,
  },
  {
    id: 'kleros',
    holding: 584.39127,
    ...restObject,
  },
  {
    id: 'oxygen',
    holding: 35.954,
    ...restObject,
  },
  {
    id: 'ethereummax',
    holding: 3183386523.8,
    ...restObject,
  },
  {
    id: 'pussy-financial',
    holding: 1235927.223,
    ...restObject,
  },
  {
    id: 'b21',
    holding: 1055.214,
    ...restObject,
  },
  // Exchange - Bitcoin Meester
  {
    id: 'mithril',
    holding: 1000,
    ...restObject,
  },
  {
    id: 'airswap',
    holding: 500,
    ...restObject,
  },
  {
    id: 'request-network',
    holding: 100,
    ...restObject,
  },
  {
    id: 'vechain',
    holding: 100,
    ...restObject,
  },
  {
    id: 'loopring',
    holding: 100,
    ...restObject,
  },
  {
    id: 'district0x',
    holding: 500,
    ...restObject,
  },
  {
    id: 'aragon',
    holding: 10,
    ...restObject,
  },
];
