import { StyledDataDisplayGroup } from './styles';

export const DataDisplayGroup: React.FC<DataDisplayGroupProps> = ({ children, className }) => {
  return <StyledDataDisplayGroup className={className}>{children}</StyledDataDisplayGroup>;
};

interface DataDisplayGroupProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}
