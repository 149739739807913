import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { interfaceProfile } from 'app/global/recoil/atoms';
import { useFindUserByID } from 'app/global/graphql/hooks/useFindUserByID';
import { QueryResult } from 'components/molecules';
import { Profile, ProfileSizeType } from 'components/atoms';
import { StyledOverlayContainer, StyledHeadingSmall, StyledHeading, ButtonLogout, Information } from './styles';

const UserProfileOverlay: React.FC<UserProfileOverlayProps> = ({ user }) => {
  // Shared state management - interface
  const interfaceSettingsState = useRecoilValue(interfaceProfile);

  const { loading: userLoading, error: userError, data: userData } = useFindUserByID('323692585468035660');

  // Handle - Logout Interaction
  const { logout } = useAuth0();

  return (
    <QueryResult error={userError} loading={userLoading} data={userData}>
      <StyledOverlayContainer isActive={interfaceSettingsState}>
        <StyledHeading title="Profile" />
        <StyledHeadingSmall title="general" />
        <Profile
          picture={user.picture}
          description={user.name}
          size={ProfileSizeType.Large}
          onInteractionHandler={() => console.log('bla')}
        />
        <Information>{user.sub}</Information>
        {/* TODO: Add A Cool looking QR code here */}
        <Information>
          {`🤖 What are you a caveman?! (or woman). You don't even have our great app. Scan the barcode thingy and get it on
        you phone now!`}
        </Information>
        <ButtonLogout
          onClickHandler={() => logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI })}
          title="Logout"
        />
      </StyledOverlayContainer>
    </QueryResult>
  );
};

export const UserProfileOverlayMemoized = memo(UserProfileOverlay);

interface UserProfileOverlayProps {
  user: any;
}
