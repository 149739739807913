import { Droppable as DroppableBeautifulDND } from 'react-beautiful-dnd';
import { Container } from './styles';

export const Droppable: React.FC<DroppableProps> = ({ droppableId, children }) => {
  return (
    <DroppableBeautifulDND droppableId={droppableId}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
          {children}
          {provided.placeholder}
        </Container>
      )}
    </DroppableBeautifulDND>
  );
};

interface DroppableProps {
  droppableId: string;
  children: React.ReactNode;
}
