import { useTranslation } from 'app/global/hooks';
import { I18N_SUPPORTED_LANGUAGES, I18NextLanguage } from 'app/global/constants/i18n';
import { StyledSelectSingle } from './styles';

export const UserLanguageSelect = ({ languages, type }: LanguageSelectProps) => {
  // Translation
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  validateLanguages(languages);

  const languageOptions = BASE_OPTIONS.filter((opt) => languages.includes(opt.value as I18NextLanguage));
  const selected = languageOptions.find((opt) => opt.value === i18n.language);

  return (
    <StyledSelectSingle
      type={type}
      options={languageOptions}
      placeholder={t('language')}
      error=""
      selected={selected}
      onSelect={(opt) => i18n.changeLanguage(opt?.value)}
      disabled={false}
    />
  );
};

// Select options
const BASE_OPTIONS = I18N_SUPPORTED_LANGUAGES.map<any>((lang) => ({
  value: lang.key,
  label: lang.label,
}));

export interface LanguageSelectProps {
  languages: I18NextLanguage[];
  type: 'header' | 'settings';
}

function validateLanguages(languages: string[]) {
  if (!languages.every((lang) => I18N_SUPPORTED_LANGUAGES.some((supportedLang) => supportedLang.key === lang))) {
    console.warn(
      `You configured a language which is not supported, it will be ignored.\nlanguages: ${languages} \nsupported languages: ${I18N_SUPPORTED_LANGUAGES.map(
        (lang) => lang.key,
      )}`,
    );
  }
}
