import styled from 'styled-components';
import { HeadingSmall } from 'components/atoms';

export const StyledDataValueGroup = styled.div``;

export const Wrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

/* Overwriting styles */

export const StyledHeadingSmall = styled(HeadingSmall)`
  z-index: ${(props) => props.theme.zIndex.z70};
  padding: 1rem 0 1rem 1rem;
  margin-bottom: 0;
  position: sticky;
  top: 0px;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
`;
