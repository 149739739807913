import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      allowMultiLoading: false,
      loadPath: 'translations/{{lng}}.json',
    },
    react: {
      useSuspense: false,
    },
    ns: ['common', 'portal'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'en',
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
  });

export default i18n;
