import { StyledCoinPriceChange } from './styles';
import { convertNumberToPercentage } from 'app/global/utils/convertNumberToPercentage';

export const CoinPriceChange: React.FC<CoinPriceChangeProps> = ({ balance }) => {
  const balanceReduced = Math.sign(balance || 0);
  const transformedBalanced = convertNumberToPercentage(balance);

  return <StyledCoinPriceChange balance={balanceReduced}>{` ${transformedBalanced}`}</StyledCoinPriceChange>;
};

interface CoinPriceChangeProps {
  balance: number | undefined | null;
}
