import styled from 'styled-components';

export const StyledNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.span`
  color: ${(props) => props.theme.globalColors.dark};
  font-family: 'Centra-No2-Bold';
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  line-height: 1rem;
  letter-spacing: 0.1em;
  user-select: none;
`;

export const Value = styled.span`
  color: ${(props) => props.theme.globalColors.light};
  font-family: 'Centra-No2-Bold';
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSize.textBase};
  line-height: 1rem;
  text-shadow: 0 1px 2px ${(props) => props.theme.globalColors.fadedPurple};
  user-select: none;
  margin-right: 2rem;
`;
