import React, { useState, ReactElement, useCallback, useEffect, useRef, useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import { SelectOptions } from 'components/atoms';
import { useOutsideClickListener } from 'app/global/hooks';
import {
  Container,
  InputWrapper,
  InputField,
  Prefix,
  Postfix,
  Placeholder,
  ArrowIcon,
  PlaceholderBackground,
  Options,
  InputError,
} from './styles';

export const SelectSingle: React.FC<SingleSelectProps> = ({
  name,
  prefix,
  options,
  selected,
  placeholder,
  onSelect,
  onBlur,
  searchable,
  hideSelected,
  disabled,
  error,
  className,
  onToggle,
  postfix,
  onSearch = () => null,
  isLoading = false,
  hasMore,
  fetchMore,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState(selected?.label || '');

  const debouncer = useMemo(() => debounce(200, onSearch), [onSearch]);
  const debouncedOnSearch = useCallback(debouncer, [onSearch, debouncer]);

  const onOutsideClick = useCallback(() => {
    setQuery(selected?.label || '');
    setIsOpen(false);
  }, [selected]);

  const menuRef = useRef(null);
  useOutsideClickListener(menuRef, onOutsideClick);

  useEffect(() => {
    if (!selected) {
      setQuery('');
    }
  }, [selected]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsOpen(true);
      setQuery(e.target.value);
      debouncedOnSearch(e.target.value);
    },
    [debouncedOnSearch],
  );

  const onInputBlur = useCallback(() => {
    if (!isOpen || isLoading) setQuery(selected?.label || '');
  }, [selected, isLoading, isOpen]);

  const onSelectOption = useCallback(
    (option: SelectOption) => {
      setIsOpen(false);
      if (searchable) setQuery(hideSelected ? '' : option.label);
      onSelect(option);
    },
    [searchable, onSelect, hideSelected],
  );

  useEffect(() => {
    if (onToggle) {
      onToggle(isOpen);
    }
  }, [onToggle, isOpen]);

  return (
    <Container className={className} disabled={disabled} hasError={!!error} ref={menuRef}>
      <InputWrapper active={isOpen || isLoading}>
        {prefix && <Prefix>{prefix}</Prefix>}
        <InputField
          name={name}
          type="text"
          autoComplete="off"
          value={searchable ? query : selected?.label || ''}
          readOnly={!searchable}
          disabled={disabled}
          onClick={() => {
            if (!searchable || options.length || isLoading) {
              setIsOpen(!isOpen);
            }
          }}
          onChange={onInputChange}
          onBlur={(e) => {
            onInputBlur();
            onBlur && onBlur(e);
          }}
        />
        <Placeholder atTop={isOpen || !!selected} disabled={disabled}>
          <PlaceholderBackground />
          {placeholder}
        </Placeholder>

        {postfix || (
          <Postfix onClick={() => searchable && setIsOpen(!isOpen)}>
            <ArrowIcon title="Open" />
          </Postfix>
        )}
      </InputWrapper>
      {!!error && <InputError>{error}</InputError>}
      <Options hidden={!isOpen}>
        {
          <SelectOptions
            options={options}
            selected={selected}
            onSelect={onSelectOption}
            isLoading={isLoading}
            hasMore={hasMore}
            fetchMore={fetchMore}
          />
        }
      </Options>
    </Container>
  );
};

export interface SelectOption {
  label: string;
  value?: string;
}

export interface CustomSelectMenuItemProps {
  option: SelectOption;
}

export interface SingleSelectProps {
  name?: string;
  prefix?: ReactElement;
  options: SelectOption[];
  selected?: SelectOption;
  query?: string;
  placeholder?: string;
  error?: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  postfix?: ReactElement;
  searchable?: boolean;
  hideSelected?: boolean; // Hides the value once an option is selected, useful if you use this as a picker which shows the selected item elsehwere
  onSelect: (value?: SelectOption) => void;
  onSearch?: (query: string) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onToggle?: (open: boolean) => void;
  hasMore?: boolean;
  fetchMore?: () => void;
}
