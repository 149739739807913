import styled from 'styled-components';
import { HeadingSmall, SignifierFlavorType } from 'components/atoms';

/* Overwriting styles */
export const SignifierLabel = styled(HeadingSmall)`
  font-size: 0.65rem;
  margin-bottom: 0;
  margin-left: 0.5rem;
`;

export const StyledSignifier = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transform: translateY(-1rem);
`;

export const SignifierIndicator = styled.div<{ flavor: SignifierFlavorType }>`
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${(props) => props.flavor === SignifierFlavorType.PinPoint && props.theme.globalColors.purple};
  background-color: ${(props) =>
    props.flavor === SignifierFlavorType.FullRange && props.theme.globalColors.fadedPurple};
  background-color: ${(props) =>
    props.flavor === SignifierFlavorType.SubRange && props.theme.globalColors.deeperPurple};
  background-color: ${(props) =>
    props.flavor === SignifierFlavorType.Highlight && props.theme.globalColors.solidPurple};
  background-color: ${(props) => props.flavor === SignifierFlavorType.Positive && props.theme.globalColors.solidGreen};
  background-color: ${(props) => props.flavor === SignifierFlavorType.Negative && props.theme.globalColors.solidPink};
  border-radius: 0.25rem;
`;

export const SignifierIndicatorWrapper = styled.div`
  margin: 4px;
`;
