import { useQuery } from '@apollo/client';
import { FindUserByIdQuery, FindUserByIdQueryVariables } from 'generated/graphql';
import { FIND_USER_BY_ID } from 'app/global/graphql/findUserByID';
import { useAuthTokenContext } from 'app/global/context/AuthTokenContext';

export const useFindUserByID = (id: string) => {
  const { getToken } = useAuthTokenContext();

  const { loading, error, data } = useQuery<FindUserByIdQuery, FindUserByIdQueryVariables>(FIND_USER_BY_ID, {
    variables: {
      id: id,
    },
    context: { clientName: 'fauna', headers: { authorization: `Bearer ${getToken()}` } },
  });

  return {
    loading,
    error,
    data,
  };
};
