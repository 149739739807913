import { Container } from './styles';

export const Tag: React.FC<TagProps> = ({ title, className }) => {
  return <Container className={className}>{title === undefined || (title === null ? `-` : `${title}`)}</Container>;
};

interface TagProps {
  title: string | number | undefined | null;
  className?: string;
}
