import { findExternalCoinData } from 'app/global/utils/findExternalCoinData';

export const calculateTotalHoldings = (coins: any, coinData: any) => {
  return coins
    .map((coin: any) => {
      // During the map we check the market specifics to get the current price
      const externalCoin = findExternalCoinData(coinData?.getCoinsMarket, coin.id);

      // Total value for this coin
      return externalCoin?.current_price * coin.holding;
    })
    .reduce((summary: number, value: number) => summary + value, 0);
};
