import { memo, useCallback } from 'react';
import useSound from 'use-sound';
import soundEffect from 'assets/sounds/tap.wav';
import { Profile, ProfileSizeType } from 'components/atoms';
import { Wrapper, Left, Right, Group, CoinInformation, Name, Holding } from './styles';

export const CoinPreviewEdit: React.FC<CoinPreviewEditProps> = ({ id, holding, image, name }) => {
  const [playSoundEffect] = useSound(soundEffect, { volume: 0.5 });

  const handleClick = useCallback(() => {
    console.log('do something with id:', id);
    playSoundEffect();
  }, [playSoundEffect, id]);

  return (
    <Wrapper onClick={() => handleClick()}>
      <Left>
        <Group>
          <Profile
            picture={image}
            description={name}
            size={ProfileSizeType.Small}
            balance={0}
            onInteractionHandler={() => console.log('test')}
          />
          <CoinInformation>
            <Name>{name}</Name>
          </CoinInformation>
        </Group>
      </Left>
      <Right>
        <Holding>100{holding}</Holding>
      </Right>
    </Wrapper>
  );
};

interface CoinPreviewEditProps {
  id: string;
  holding: number;
  image: string | undefined | null;
  name: string | undefined;
  symbol: string | undefined | null;
}

export const CoinPreviewEditMemoized = memo(CoinPreviewEdit);
