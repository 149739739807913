import styled from 'styled-components';
import { media } from 'app/global/styles/mediaViewport';

export const StyledCoinPreviews = styled.div`
  display: flex;
  flex-direction: column;
  width: 38.235294118%;
  min-width: 100%;
  height: calc(100vh - 6rem);
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  border-radius: 1rem;
  overflow: scroll;
  transition: width 600ms;

  /* Hide the scrollbar */

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Responsive - overwrites */

  ${media.xl} {
    min-width: 34rem;
    height: calc(100vh - 14rem);
    padding: 0 1rem 1rem 1rem;
    margin-right: 0.5rem;
  }

  ${media.xxl} {
    min-width: 34rem;
  }
`;
