import { StyledDataValueGroup, StyledHeadingSmall, Wrapper } from './styles';

export const DataValueGroup: React.FC<DataValueGroupProps> = ({ title, children }) => {
  return (
    <StyledDataValueGroup>
      <StyledHeadingSmall title={title} />
      <Wrapper>{children}</Wrapper>
    </StyledDataValueGroup>
  );
};

interface DataValueGroupProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}
