import { useQuery } from '@apollo/client';
import { GetCoinsHoldingListQuery, GetCoinsHoldingListQueryVariables } from 'generated/graphql';
import { GET_COINS_HOLDING_LIST } from 'app/global/graphql/getCoinsHoldingList';
import { UserCurrencyEnums } from 'app/global/constants/userCurrencyEnums';

export const useCoinsHoldingList = (currency: UserCurrencyEnums, ids: string, pollInterval: number) => {
  const { loading, error, data } = useQuery<GetCoinsHoldingListQuery, GetCoinsHoldingListQueryVariables>(
    GET_COINS_HOLDING_LIST,
    {
      variables: {
        getCoinsCoinsHoldingListVsCurrency: currency,
        getCoinsCoinsHoldingListIds: ids,
        getCoinsHoldingListSparkline: true,
      },
      pollInterval,
    },
  );

  return {
    loading,
    error,
    data,
  };
};
