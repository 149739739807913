import { EditPortfolioOverlay } from './components';
import { StyledOverlayEditPortfolio } from './styles';

export const OverlayEditPortfolio: React.FC = () => {
  return (
    <StyledOverlayEditPortfolio>
      <EditPortfolioOverlay />
    </StyledOverlayEditPortfolio>
  );
};
