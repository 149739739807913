import { StyledCoinListCointainerSkeleton, SkeletonPreview } from './styles';

export const CoinListCointainerSkeleton: React.FC = () => {
  return (
    <StyledCoinListCointainerSkeleton>
      <SkeletonPreview />
      <SkeletonPreview />
      <SkeletonPreview />
      <SkeletonPreview />
      <SkeletonPreview />
    </StyledCoinListCointainerSkeleton>
  );
};
