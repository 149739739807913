import React, { useState } from 'react';
import { Checkbox, CodeBlock, Button, Heading, HeadingSmall } from '../../atoms';
import { Wrapper, InputWrapper, OutputWrapper, InputList, CheckboxContainer, ButtonGroup } from './styles';

export const CodePreview: React.FC<CodePreviewProps> = ({ title, data, inputValues }) => {
  const [selectionValue, setSelectionValue] = useState(true);

  return (
    <>
      <Heading title={title} />
      <Wrapper>
        <InputWrapper>
          <HeadingSmall title="INPUT" />
          <InputList>
            {inputValues?.map((inputValue, i) => (
              <CheckboxContainer key={'test' + i}>
                <Checkbox
                  name={inputValue}
                  value={inputValue}
                  onSelect={() => setSelectionValue(!selectionValue)}
                  selected={selectionValue}
                  disabled={false}
                  label={inputValue}
                />
              </CheckboxContainer>
            ))}
          </InputList>
          <ButtonGroup>
            <Button title="Try it" />
          </ButtonGroup>
        </InputWrapper>
        <OutputWrapper>
          <HeadingSmall title="OUTPUT" />
          <CodeBlock data={data} language="javascript" />
        </OutputWrapper>
      </Wrapper>
    </>
  );
};

interface CodePreviewProps {
  title: string;
  data: any;
  inputValues?: string[];
}
