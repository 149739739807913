import styled from 'styled-components';

export const BarWeeklyStart = styled.div<{ percentage: number }>`
  height: 4px;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.theme.globalColors.fadedPurple};
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

export const BarDailyStart = styled.div<{ percentage: number }>`
  height: 4px;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
`;

export const BarCurrent = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${(props) => props.theme.globalColors.purple};
  box-shadow: ${(props) => props.theme.globalColors.transPurple} 0px 0px 12px 6px;
  border-radius: 2px;
`;

export const BarDailyEnd = styled.div<{ percentage: number }>`
  height: 4px;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
`;

export const BarWeeklyEnd = styled.div<{ percentage: number }>`
  height: 4px;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.theme.globalColors.fadedPurple};
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;
