import { SyntheticEvent } from 'react';
import { Label, Slider, Input, InputWrapper, Description } from './styles';

export const Toggle = ({ label, isChecked, onToggle, className }: ToggleProps) => (
  <Label className={className}>
    <Description>{label}</Description>
    <InputWrapper isChecked={isChecked}>
      <Input type="checkbox" checked={isChecked} onChange={onToggle} />
      <Slider />
    </InputWrapper>
  </Label>
);

export interface ToggleProps {
  className?: string;
  label?: string;
  isChecked?: boolean;
  disabled?: boolean;
  onToggle: (e: SyntheticEvent) => void;
}
