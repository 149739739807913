import { QueryResult, DataValueGroup } from 'components/molecules';
import { DataDisplay, DateDisplayType, RangeWeekly } from 'components/atoms';
import { StyledDataDisplayGroup } from './styles';

export const SectionLast24Hours: React.FC<SectionLast24HoursProps> = ({ loading, error, data }) => {
  // Shorthand - Market data path
  const marketData = data?.getCoinsMarket[0];

  // Get values - max & min from sparkline array
  let sparkLineMax;
  let sparkLineMin;

  if (marketData?.sparkline_in_7d.price) {
    sparkLineMax = Math.max(...marketData?.sparkline_in_7d.price);
    sparkLineMin = Math.min(...marketData?.sparkline_in_7d.price);
  }

  // TODO: Open charts by clicking spark to view details
  // TODO: Display sparkline last 24 hours

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <DataValueGroup title="Last 24 hours">
        <>
          <StyledDataDisplayGroup>
            <DataDisplay label="chart" value={marketData?.sparkline_in_7d?.price} dataType={DateDisplayType.Chart} />
          </StyledDataDisplayGroup>
          <StyledDataDisplayGroup>
            <DataDisplay label="current price" value={marketData?.current_price} />
          </StyledDataDisplayGroup>
          <StyledDataDisplayGroup>
            <DataDisplay label="low" value={marketData?.low_24h} />
            <DataDisplay label="high" value={marketData?.high_24h} />
          </StyledDataDisplayGroup>
        </>
        <StyledDataDisplayGroup>
          <DataDisplay
            label="price change"
            value={marketData?.price_change_24h}
            valueSecondary={marketData?.price_change_percentage_24h}
            dataType={DateDisplayType.PriceChange}
          />
          <DataDisplay
            label="market cap change"
            value={marketData?.market_cap_change_24h}
            valueSecondary={marketData?.market_cap_change_percentage_24h}
            dataType={DateDisplayType.MarketCapChange}
          />
        </StyledDataDisplayGroup>
        <StyledDataDisplayGroup>
          <RangeWeekly
            label="compared to weekly range"
            weeklyLow={sparkLineMin}
            weeklyHigh={sparkLineMax}
            dailyLow={marketData?.low_24h}
            dailyHigh={marketData?.high_24h}
            current={marketData?.current_price}
          />
        </StyledDataDisplayGroup>
      </DataValueGroup>
    </QueryResult>
  );
};

interface SectionLast24HoursProps {
  data: any;
  error: any;
  loading: any;
}
