import styled, { css, keyframes } from 'styled-components';
import { media } from 'app/global/styles/mediaViewport';

export const StyledMain = styled.main<{ isZonedOut: boolean }>`
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  /* Conditionally - add CSS block */

  ${(props) => props.isZonedOut && zoomOut}

  /* Responsive - overwrites */

  ${media.sm} {
    max-width: ${(props) => props.theme.breakpoints.sm}px;
  }

  ${media.md} {
    max-width: ${(props) => props.theme.breakpoints.md}px;
  }

  ${media.lg} {
    max-width: ${(props) => props.theme.breakpoints.lg}px;
  }

  ${media.xl} {
    max-width: ${(props) => props.theme.breakpoints.xl}px;
  }

  ${media.xxl} {
    max-width: ${(props) => props.theme.breakpoints.xxl}px;
  }
`;

const zoomOutAnimation = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.75);
  }
`;

const zoomOut = css`
  animation-name: ${zoomOutAnimation};
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;
