import { useRecoilValue } from 'recoil';
import { interfaceFocus } from 'app/global/recoil/atoms';
import { OverlayEditPortfolio } from 'components/organisms';
import { StyledOverlay } from './styles';

export const Overlay: React.FC = () => {
  // Shared State
  const interfaceFocusState = useRecoilValue(interfaceFocus);

  return (
    <StyledOverlay isZonedOut={interfaceFocusState}>
      <OverlayEditPortfolio />
    </StyledOverlay>
  );
};
