interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const ArrowLeft = ({ title, titleId }: SVGRProps) => {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={titleId}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.293 15.536a1 1 0 000 1.414zm0 1.414l2.828 2.828a1 1 0 001.415-1.414l-.88-.879h11.587a1 1 0 100-2h-12a1 1 0 00-.074.003l1.367-1.367a1 1 0 10-1.415-1.414l-2.827 2.827"
        fill="#fff"
      />
    </svg>
  );
};
