import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interfaceSortingOption, interfaceSortingDirection } from 'app/global/recoil/atoms';
import { SortingType } from 'app/global/constants/sortingEnums';
import { SortingDirectionType } from 'app/global/constants/sortingDirectionEnums';
import { StyledUserCoinSortSelect, StyledSelectSingle } from './styles';

export const UserCoinSortSelect: React.FC = () => {
  // Shared state management
  const [interfaceSortingOptionState, setInterfaceSortingOptionState] = useRecoilState(interfaceSortingOption);
  const interfaceSortingDirectionState = useRecoilValue(interfaceSortingDirection);

  // Select options
  const options = [
    {
      label: `Price Change - (${interfaceSortingDirectionState === SortingDirectionType.Ascending ? 'ASC' : 'DESC'})`,
      value: SortingType.PriceChange,
    },
    {
      label: `Market Cap - (${interfaceSortingDirectionState === SortingDirectionType.Ascending ? 'ASC' : 'DESC'})`,
      value: SortingType.MarketCap,
    },
    {
      label: `Price - (${interfaceSortingDirectionState === SortingDirectionType.Ascending ? 'ASC' : 'DESC'})`,
      value: SortingType.Price,
    },
    {
      label: `Coin Name - (${interfaceSortingDirectionState === SortingDirectionType.Ascending ? 'ASC' : 'DESC'})`,
      value: SortingType.CoinName,
    },
  ];

  // Initial local state
  const initialState = {
    label: `Price Change - (${interfaceSortingDirectionState === SortingDirectionType.Ascending ? 'ASC' : 'DESC'})`,
    value: interfaceSortingOptionState,
  };

  // Local state management
  const [selected, setSelected] = useState<any>(initialState);

  // Monitor select value change
  useEffect(() => {
    if (selected?.value) {
      setInterfaceSortingOptionState(selected?.value);
    }
  }, [selected, setInterfaceSortingOptionState, interfaceSortingDirectionState]);

  return (
    <StyledUserCoinSortSelect>
      <StyledSelectSingle
        options={options}
        placeholder="Sorted By"
        error=""
        selected={selected}
        onSelect={setSelected}
        disabled={false}
      />
    </StyledUserCoinSortSelect>
  );
};
