import React, { useMemo, PropsWithChildren } from 'react';
import { Waypoint } from 'react-waypoint';
import { Container, Loading } from './styles';
import { Spinner } from 'components/atoms';

export const InfiniteList: React.FC<InfiniteListProps> = ({
  children,
  fetchMore,
  hasMore,
  isLoading,
  offset = 5,
  dataCy,
  scrollableAncestor,
  className,
}) => {
  const amountOfChildren = useMemo(() => (!children ? 0 : React.Children.count(children)), [children]);

  if (amountOfChildren === 0)
    return (
      <Container className={className}>
        {hasMore && <Waypoint onEnter={fetchMore} scrollableAncestor={scrollableAncestor} />}
        {isLoading && (
          <Loading>
            <Spinner scale={0.75} />
          </Loading>
        )}
      </Container>
    );

  return (
    <Container data-cy={dataCy} className={className}>
      {React.Children.map(children, (child, index) => (
        <>
          {React.cloneElement(child as any)}
          {hasMore && index === amountOfChildren - offset && (
            <Waypoint onEnter={fetchMore} scrollableAncestor={scrollableAncestor} />
          )}
        </>
      ))}
      {isLoading && (
        <Loading>
          <Spinner scale={0.75} />
        </Loading>
      )}
    </Container>
  );
};

export type InfiniteListProps = PropsWithChildren<{
  fetchMore?: () => void;
  isLoading?: boolean;
  hasMore?: boolean;
  offset?: number;
  dataCy?: string;
  scrollableAncestor?: React.ReactNode;
  className?: string;
}>;
