import styled from 'styled-components';
import { SelectSingle } from 'components/atoms';

export const StyledSelectSingle = styled(SelectSingle)<{ type: 'header' | 'settings' }>`
  width: 100%;

  ${(props) => props.type === 'header' && 'max-width: 8rem;'};
  ${(props) => props.type === 'settings' && 'max-width: 100%;'};
  ${(props) => props.type === 'settings' && 'margin-top: 1rem;'};

  > label {
    ${(props) => props.type === 'header' && `background-color: ${props.theme.globalColors.deeperPurple};`};
  }
`;
