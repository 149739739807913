import React, { useEffect } from 'react';
import Prism from 'prismjs';
import { StyledCodeBlock } from './styles';

export const CodeBlock: React.FC<CodeBlockProps> = ({ data, language }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <StyledCodeBlock>
      <code className={`language-${language}`}>{JSON.stringify(data, null, 2)}</code>
    </StyledCodeBlock>
  );
};

interface CodeBlockProps {
  data: any;
  language: string;
}
