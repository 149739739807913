import { gql } from '@apollo/client';

export const GET_COINS_MARKET_CHART = gql`
  query GetCoinsMarketChart(
    $getCoinsMarketChartId: String!
    $getCoinsMarketChartVsCurrency: String!
    $getCoinsMarketChartDays: Float!
  ) {
    getCoinsMarketChart(
      id: $getCoinsMarketChartId
      vs_currency: $getCoinsMarketChartVsCurrency
      days: $getCoinsMarketChartDays
    ) {
      market_caps
      total_volumes
      prices
    }
  }
`;
