import { createGlobalStyle } from 'styled-components';
import CentraNo2Bold from 'assets/fonts/CentraNo2-Bold.woff2';
import CentraNo2Regular from 'assets/fonts/CentraNo2-Regular.woff2';
import CentraNo2Thin from 'assets/fonts/CentraNo2-Thin.woff2';
import GalaxieCopernicusBook from 'assets/fonts/GalaxieCopernicus-Book.woff';
import GalaxieCopernicusBookItalic from 'assets/fonts/GalaxieCopernicus-BookItalic.woff';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Centra-No2-Bold';
    src: local('Centra-No2-Bold'), url(${CentraNo2Bold}) format('woff2');
  }

  @font-face {
    font-family: 'Centra-No2-Regular';
    src: local('Centra-No2-Regular'), url(${CentraNo2Regular}) format('woff2');
  }

  @font-face {
    font-family: 'Centra-No2-Thin';
    src: local('Centra-No2-Thin'), url(${CentraNo2Thin}) format('woff2');
  }

  @font-face {
    font-family: 'Galaxie-Copernicus-Book';
    src: local('Galaxie-Copernicus-Book'), url(${GalaxieCopernicusBook}) format('woff');
  }

  @font-face {
    font-family: 'Galaxie-Copernicus-BookItalic';
    src: local('Galaxie-Copernicus-BookItalic'), url(${GalaxieCopernicusBookItalic}) format('woff');
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    transition: color 0.3s ease, background-color 0.3s ease;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-family: Centra-No2-Regular, sans-serif;
    font-weight: normal;
    color: hsl(208, 100%, 8%);
    -webkit-font-smoothing: subpixel-antialiased;
  }
  
  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Centra-No2-Regular, sans-serif;
    line-height: 1;
    background-color: hsl(235, 41%, 17%);
  }

  #root {
    display: flex;
    justify-content: center;
  }

  p, button, a {
    font-family: Centra-No2-Regular, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Centra-No2-Bold, sans-serif;
    font-weight: bold;
  }

  a{
    text-decoration: none;
  }

  button{
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
  }

  // Reset: https://meyerweb.com/eric/tools/css/reset/

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  // Custom styles - Notifications

  .notification-increase {
    background-color: rgba(119, 217, 155, 1);
    color: hsl(0, 0%, 100%);
    border-radius: 1rem;
    margin: 1rem;
    overflow: hidden;

    .Toastify__progress-bar-theme--light {
      background: #fff
    }

    .Toastify__toast-body {
      > div {
        width: 100%;
      }
    }
  }

  .notification-decrease {
    background-color: rgba(252, 132, 132, 1);
    color: hsl(0, 0%, 100%);
    border-radius: 1rem;
    margin: 1rem;
    overflow: hidden;

    .Toastify__progress-bar-theme--light {
      background: #fff;
    }

    .Toastify__toast-body {
      > div {
        width: 100%;
      }
    }
  }

  .notification-setting-change {
    background-color: rgba(173, 155, 246, 1);
    color: hsl(0, 0%, 100%);
    border-radius: 1rem;
    margin: 1rem;
    overflow: hidden;

    .Toastify__progress-bar-theme--light {
      background: #fff;
    }

    .Toastify__toast-body {
      > div {
        width: 100%;
      }
    }
  }

  // Custom styles - Code Highlighting

  .language-javascript .punctuation {
    color: rgba(173, 155, 246, 1);
  }

  .language-javascript .string {
    color: hsl(0, 0%, 100%);
  }

  .language-javascript .operator {
    color: rgba(173, 155, 246, 1);
  }

  .language-javascript .operator + .string, .number, .keyword {
    color: rgba(119, 217, 155, 1);
  }
`;
