import styled from 'styled-components';
import { BrandingLogoLetters } from 'assets/icons';
import { HeadingSmall, Button } from 'components/atoms';
import { media } from 'app/global/styles/mediaViewport';

export const StyledHeader = styled.header<{ isZonedOut: boolean }>`
  z-index: ${(props) => props.theme.zIndex.z100};
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 4rem;
  background-color: ${(props) =>
    props.isZonedOut ? props.theme.globalColors.blurryPurple : props.theme.globalColors.deeperPurple};
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);

  ${media.xl} {
    background-color: ${(props) => props.theme.globalColors.blurryPurple};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* Responsive - overwrites */

  ${media.sm} {
    max-width: ${(props) => props.theme.breakpoints.sm}px;
  }

  ${media.md} {
    max-width: ${(props) => props.theme.breakpoints.md}px;
  }

  ${media.lg} {
    max-width: ${(props) => props.theme.breakpoints.lg}px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${media.xl} {
    max-width: ${(props) => props.theme.breakpoints.xl}px;
  }

  ${media.xxl} {
    max-width: ${(props) => props.theme.breakpoints.xxl}px;
  }
`;

export const Start = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > svg path {
    fill: ${(props) => props.theme.globalColors.solidPurple};
  }
`;

export const End = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

/* Overwriting styles */

export const Heading = styled(HeadingSmall)`
  font-size: 0.875rem;
  color: ${(props) => props.theme.globalColors.solidPurple};
  padding: 1rem 0 1rem 0;
  margin-bottom: 0;
`;

export const ButtonLogin = styled(Button)`
  margin-left: 1rem;
  width: 6rem;

  /* Responsive - overwrites */

  ${media.xl} {
    width: 8rem;
  }
`;

export const Logo = styled(BrandingLogoLetters)`
  height: 0.75rem;
`;
