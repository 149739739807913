import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  padding: 1rem 0;
  transition: all 300ms;
  border-top: 1px solid ${(props) => props.theme.globalColors.deepPurple};
`;

export const Left = styled.div`
  flex: 1 1 0;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CoinInformation = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const CoinName = styled.span`
  position: absolute;
  top: -1.25rem;
  left: -0.5rem;
  display: block;
  font-family: 'Centra-No2-Bold';
  color: ${(props) => props.theme.globalColors.fadedPurple};
  margin-left: 0.5rem;
  user-select: none;
  line-height: 2rem;
  font-family: 'Centra-No2-Bold';
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin-top: 1rem;
  margin-left: 1rem;
`;

export const Name = styled.span`
  display: block;
  font-family: 'Centra-No2-Regular';
  font-size: ${(props) => props.theme.fontSize.textSmall};
  color: ${(props) => props.theme.globalColors.light};
  user-select: none;
  line-height: 1.75rem;
  letter-spacing: 0.1em;
  margin-left: 0.5rem;
`;

export const Holding = styled.span`
  color: ${(props) => props.theme.globalColors.fadedPurple};
  font-family: 'Centra-No2-Bold';
  font-size: ${(props) => props.theme.fontSize.textSmall};
  line-height: 1.75rem;
  letter-spacing: 0.1em;
  user-select: none;
  margin-left: 1rem;
`;
