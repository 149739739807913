import { StyledCoinHolding, Label, Holding } from './styles';

export const CoinHolding: React.FC<CoinHoldingProps> = ({ symbol, price, holding }) => {
  return (
    <StyledCoinHolding>
      <Label>{symbol}</Label>
      <Holding>{price ? `$${(price * holding).toFixed(2)}` : `-`}</Holding>
    </StyledCoinHolding>
  );
};

interface CoinHoldingProps {
  symbol: string | undefined | null;
  price: number | undefined | null;
  holding: number;
}
