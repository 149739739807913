import { StyledNotification, Label, Value } from './styles';

export const Notification: React.FC<NotificationProps> = ({ label, value }) => {
  return (
    <StyledNotification>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </StyledNotification>
  );
};

interface NotificationProps {
  label: string;
  value: string | number;
}
