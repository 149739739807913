import { Option, EmptyOption } from './styles';
import { InfiniteList } from 'components/molecules';

export const SelectOptions = ({ options, onSelect, selected, isLoading, hasMore, fetchMore }: SelectOptionsProps) => {
  return options.length || isLoading ? (
    <InfiniteList isLoading={!!isLoading} hasMore={hasMore} fetchMore={fetchMore} offset={5}>
      {options.map((option, i) => (
        <Option
          key={option.value || `option_${i}`}
          onClick={() => onSelect(option)}
          selected={option.value === selected?.value}
        >
          {option.label}
        </Option>
      ))}
    </InfiniteList>
  ) : (
    <EmptyOption>No results found</EmptyOption>
  );
};

export interface SelectOption {
  label: string;
  value?: string;
}

export interface CustomSelectMenuItemProps {
  option: SelectOption;
}

export interface SelectOptionsProps {
  options: SelectOption[];
  selected?: SelectOption;
  onSelect: (option: SelectOption) => void;
  isLoading?: boolean;
  fetchMore?: () => void;
  hasMore?: boolean;
}
