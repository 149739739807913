import styled from 'styled-components';

export const StyledCoinDetailsFooter = styled.footer`
  position: sticky;
  bottom: -1rem;
  z-index: ${(props) => props.theme.zIndex.z100};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
`;

export const Previous = styled.div``;

export const Next = styled.div``;
