import { DataDisplayWrapper, Sparkline } from 'components/atoms';
import { Value, StyledTag } from './styles';
import { convertBigNumberToUserNumber } from 'app/global/utils/convertBigNumberToUserNumber';
import { convertNumberToPercentage } from 'app/global/utils/convertNumberToPercentage';

export const DataDisplay: React.FC<DataDisplayProps> = ({ label, value, valueSecondary, dataType }) => {
  // Handle - Data value of type percentage
  if (dataType === DateDisplayType.Percentage && typeof value === 'number') {
    const numberConverted = convertNumberToPercentage(value);

    return (
      <DataDisplayWrapper label={label}>
        <Value>{numberConverted}</Value>
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type big number
  if (dataType === DateDisplayType.BigNumber && typeof value === 'number') {
    const numberConverted = convertBigNumberToUserNumber(value);

    return (
      <DataDisplayWrapper label={label}>
        <Value>{numberConverted}</Value>
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type price change
  if (dataType === DateDisplayType.PriceChange && typeof value === 'number' && typeof valueSecondary === 'number') {
    const percentageNumberConverted = convertNumberToPercentage(valueSecondary);
    const balanceReduced = Math.sign(valueSecondary || 0);

    return (
      <DataDisplayWrapper label={label}>
        {/* TODO: Handle Negative numbers better */}
        {value > 0 ? <Value>{value}</Value> : <Value>{value.toPrecision(3)}</Value>}
        <StyledTag title={percentageNumberConverted} balance={balanceReduced} />
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type market cap change
  if (dataType === DateDisplayType.MarketCapChange && typeof value === 'number' && typeof valueSecondary === 'number') {
    const bigNumberConverted = convertBigNumberToUserNumber(value);
    const percentageNumberConverted = convertNumberToPercentage(valueSecondary);
    const balanceReduced = Math.sign(valueSecondary || 0);

    return (
      <DataDisplayWrapper label={label}>
        <Value>{bigNumberConverted}</Value>
        <StyledTag title={percentageNumberConverted} balance={balanceReduced} />
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type holdings
  if (dataType === DateDisplayType.Holding && typeof value === 'number' && typeof valueSecondary === 'string') {
    return (
      <DataDisplayWrapper label={label}>
        <Value>{value}</Value>
        <StyledTag title={valueSecondary} balance={0} />
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type holdings
  if (dataType === DateDisplayType.HoldingTotal && typeof value === 'number' && typeof valueSecondary === 'number') {
    const percentageFromTotal = (value * 100) / valueSecondary;

    return (
      <DataDisplayWrapper label={label}>
        <Value>{`$${value.toFixed(2)}`}</Value>
        <StyledTag title={`${percentageFromTotal.toFixed(2)}%`} balance={0} />
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type chart
  if (dataType === DateDisplayType.Chart) {
    return (
      <DataDisplayWrapper label={label}>
        <Sparkline data={value} />
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type copy text
  if (dataType === DateDisplayType.CopyText) {
    return (
      <DataDisplayWrapper label={label}>
        <Value>{value}</Value>
      </DataDisplayWrapper>
    );
  }

  // Handle - Data value of type external link
  if (dataType === DateDisplayType.ExternalLink) {
    return (
      <DataDisplayWrapper label={label}>
        <Value>{value}</Value>
      </DataDisplayWrapper>
    );
  }

  return (
    <DataDisplayWrapper label={label}>
      {/* Return value - Undefined or null */}
      {value === undefined || (value === null && <Value>-</Value>)}
      {/* Return value - DataType: Undefined */}
      {value !== undefined && value !== null && dataType === undefined && <Value>{value}</Value>}
    </DataDisplayWrapper>
  );
};

interface DataDisplayProps {
  label: string;
  // TODO: Fix the types
  value: any;
  valueSecondary?: string | number | boolean | undefined | null;
  dataType?: DateDisplayType;
}

export enum DateDisplayType {
  Percentage = 'PERCENTAGE',
  BigNumber = 'BIG_NUMBER',
  PriceChange = 'PRICE_CHANGE',
  MarketCapChange = 'MARKET_CAP_CHANGE',
  Holding = 'HOLDING',
  HoldingTotal = 'HOLDING_TOTAL',
  Chart = 'CHART',
  CopyText = 'COPY_TEXT',
  ExternalLink = 'EXTERNAL_LINK',
}
