import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
import soundEffect from 'assets/sounds/tap.wav';
import { Profile, ProfileSizeType } from 'components/atoms';
import { CoinPriceChange, CoinHolding } from './components';
import { Wrapper, Left, Right, Group, CoinInformation, CoinName, CoinPrice, Holding } from './styles';

export const CoinPreviewPortfolio: React.FC<CoinPreviewEditProps> = ({
  id,
  holding,
  image,
  name,
  symbol,
  currentPrice,
  priceChangePercentage24H,
  isActive,
}) => {
  const [playSoundEffect] = useSound(soundEffect, { volume: 0.5 });

  const handleClick = useCallback(() => {
    playSoundEffect();
  }, [playSoundEffect]);

  return (
    <Link to={`?name=${id}`}>
      <Wrapper isActive={isActive} onClick={() => handleClick()}>
        <Left>
          <Group>
            <Profile
              picture={image}
              description={name}
              size={ProfileSizeType.Medium}
              balance={priceChangePercentage24H}
              onInteractionHandler={() => console.log('test')}
            />
            <CoinInformation>
              <CoinName>{name}</CoinName>
              <CoinPrice>${currentPrice}</CoinPrice>
            </CoinInformation>
          </Group>
          <Group>
            <CoinPriceChange balance={priceChangePercentage24H} />
            <Holding>
              {holding} - <span>{symbol}</span>
            </Holding>
          </Group>
        </Left>
        <Right>
          <CoinHolding symbol={symbol} price={currentPrice} holding={holding} />
        </Right>
      </Wrapper>
    </Link>
  );
};

interface CoinPreviewEditProps {
  id: string;
  holding: number;
  image: string | undefined | null;
  name: string | undefined;
  symbol: string | undefined | null;
  currentPrice: number | undefined | null;
  priceChangePercentage24H: number | undefined | null;
  isActive?: boolean;
}

export const CoinPreviewPortfolioMemoized = memo(CoinPreviewPortfolio);
