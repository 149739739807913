import { useQuery } from '@apollo/client';
import { QueryResult, CodePreview } from 'components/molecules';
import { GET_SUPPORTED_VS_CURRENCIES } from 'app/global/graphql/getSupportedVsCurrencies';
import { GetSupportedVsCurrenciesQuery, GetSupportedVsCurrenciesQueryVariables } from 'generated/graphql';

export const SupportedVsCurrenciesData: React.FC = () => {
  const { loading, error, data } = useQuery<GetSupportedVsCurrenciesQuery, GetSupportedVsCurrenciesQueryVariables>(
    GET_SUPPORTED_VS_CURRENCIES,
  );

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <CodePreview title={'Supported Vs Currencies data'} data={data} />
    </QueryResult>
  );
};
