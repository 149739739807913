import { useRecoilState } from 'recoil';
import { ReactSVG } from 'react-svg';
import useSound from 'use-sound';
import { interfaceFocus, interfaceSettings } from 'app/global/recoil/atoms';
import soundEffect from 'assets/sounds/click.wav';
import { StyledUserSettingsButton } from './styles';

export const UserSettingsButton: React.FC = () => {
  // Initialize - sound effect
  const [playSoundEffect] = useSound(soundEffect);

  // Shared state management - interface
  const [interfaceFocusState, setInterfaceFocusState] = useRecoilState(interfaceFocus);
  const [interfaceSettingsState, setInterfaceSettingsState] = useRecoilState(interfaceSettings);

  const handleInteraction = (newState: boolean) => {
    playSoundEffect();
    setInterfaceFocusState(newState);
    setInterfaceSettingsState(newState);
  };

  return (
    <StyledUserSettingsButton
      interfaceSettingsState={interfaceSettingsState}
      onClick={() => handleInteraction(!interfaceFocusState)}
    >
      <ReactSVG src="icons/settings.svg" />
    </StyledUserSettingsButton>
  );
};
