import { StyledSegmentController } from './styles';

export const SegmentController: React.FC<SegmentControllerProps> = ({ children, className }) => {
  return <StyledSegmentController className={className}>{children}</StyledSegmentController>;
};

interface SegmentControllerProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}
