import { ApolloLink, ApolloClient, ApolloProvider as Provider, HttpLink, InMemoryCache } from '@apollo/client';

const coingecko = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
});

const fauna = new HttpLink({
  uri: 'https://graphql.fauna.com/graphql',
});

const client = new ApolloClient({
  link: ApolloLink.split((operation) => operation.getContext().clientName === 'fauna', fauna, coingecko),
  cache: new InMemoryCache(),
});

export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider client={client}>{children}</Provider>;
};
