import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MakeCancelable, makeCancelable } from 'app/global/utils/makeCancelable';

const AuthTokenContext = createContext<AuthTokenContextValue | null>(null);

export const useAuthTokenContext = () => {
  const context = useContext(AuthTokenContext);

  if (!context) throw new Error('useAuthTokenContext can only be used inside AuthTokenContext');

  return context as AuthTokenContextValue;
};

export const AuthTokenProvider: React.FC = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const tokenRef = useRef(token);

  // Fetches the access token on init
  useEffect(() => {
    async function call() {
      const accessToken = await getAccessTokenSilently();
      tokenRef.current = accessToken;
      setToken(accessToken);
    }

    let callRef: MakeCancelable;
    if (isAuthenticated) callRef = makeCancelable(call());

    return () => {
      if (callRef) callRef.cancel();
    };
  }, [isAuthenticated, getAccessTokenSilently]);

  const value = useMemo(
    () => ({
      getToken: () => tokenRef.current,
    }),
    [],
  );
  return <AuthTokenContext.Provider value={value}>{children}</AuthTokenContext.Provider>;
};

interface AuthTokenContextValue {
  getToken: () => string;
}
