import { Legend } from 'components/molecules';
import { Signifier, SignifierFlavorType } from 'components/atoms';
import { StyledDataDisplaySentiment, InnerWrapper, BarPositive, BarNegative } from './styles';

export const DataDisplaySentiment: React.FC<DataDisplaySentimentProps> = ({ positive, negative }) => {
  return (
    <StyledDataDisplaySentiment label="sentiment">
      <InnerWrapper>
        {positive ? <BarPositive positivePercentage={positive} /> : <></>}
        {negative ? <BarNegative negativePercentage={negative} /> : <></>}
      </InnerWrapper>
      <Legend
        signifiers={
          <>
            <Signifier label="positive" flavor={SignifierFlavorType.Positive} />
            <Signifier label="negative" flavor={SignifierFlavorType.Negative} />
          </>
        }
      />
    </StyledDataDisplaySentiment>
  );
};

interface DataDisplaySentimentProps {
  positive: number | undefined | null;
  negative: number | undefined | null;
}
