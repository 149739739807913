import { useRecoilValue } from 'recoil';
import { interfaceFocus } from 'app/global/recoil/atoms';
import { StyledMain } from './styles';

interface MainProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Main: React.FC<MainProps> = ({ children }) => {
  // Shared State
  const interfaceFocusState = useRecoilValue(interfaceFocus);

  return <StyledMain isZonedOut={interfaceFocusState}>{children}</StyledMain>;
};
