import { LineChart, Line, ResponsiveContainer, YAxis } from 'recharts';
import { StyledCoinGraph } from './styles';

export const Sparkline: React.FC<SparklineProps> = ({ data }) => {
  return (
    <StyledCoinGraph>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={100} data={data}>
          <YAxis hide={true} domain={['auto', 'auto']} />
          <Line
            type="monotone"
            dataKey={(point) => point++}
            stroke="hsla(242, 20%, 35%, 1)"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledCoinGraph>
  );
};

interface SparklineProps {
  data: any;
}
