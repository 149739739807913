import styled from 'styled-components';
import { HeadingSmall, Heading, Button } from 'components/atoms';
import { OverlayContainer } from 'components/molecules';
import { media } from 'app/global/styles/mediaViewport';

// Overwrite styling

export const StyledHeading = styled(Heading)`
  z-index: ${(props) => props.theme.zIndex.z90};
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  padding: 2rem 0 0 0;
`;

export const StyledHeadingSmall = styled(HeadingSmall)`
  z-index: ${(props) => props.theme.zIndex.z90};
  line-height: 2;
  margin-bottom: 0;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
  padding: 2rem 0 1rem 0;
`;

export const StyledOverlayContainer = styled(OverlayContainer)`
  flex-direction: column;
  left: 0;
  top: calc(65px + 1rem);
  width: 25.5rem;
  width: calc(100vw - 2rem);
  height: calc(100vh - 16rem);

  /* Responsive - overwrites */

  ${media.sm} {
    width: 25.5rem;
    height: calc(100vh - 20rem);
  }
`;

export const Information = styled.span`
  color: ${({ theme }) => theme.globalColors.light};
`;

/* Overwriting styles */

export const ButtonLogout = styled(Button)`
  width: 100%;
  line-height: 40px;
  margin-top: 1rem;
`;
