import styled, { css } from 'styled-components';
import { HeadingSmall } from 'components/atoms';
import { SelectArrow } from 'assets/icons/SelectArrow';

export const Container = styled.div`
  flex: 1 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
`;

export const Header = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-height: 4rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled(HeadingSmall)`
  color: ${(props) => props.theme.globalColors.solidPurple};
  line-height: 1.75rem;
  margin-bottom: 0;
  padding: 0.5rem;
  text-align: center;
`;

export const AngleContainer = styled.div<{ open: boolean }>`
  position: relative;
  top: 2px;
  transform: rotate(${({ open }) => (open ? '0deg' : '180deg')});
  transition: transform 0.3s ease;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
`;

export const ContentContainer = styled.div``;

export const Content = styled.div<{ open: boolean; noOutline?: boolean }>`
  width: 100%;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, padding 0.3s;

  ${({ open }) =>
    open &&
    css`
      padding-bottom: 1rem;
      padding-top: 16px;
      height: 100%;
      opacity: 1;
    `}

  ${({ noOutline }) =>
    noOutline &&
    css`
      border: none;

      > ${ContentContainer} {
        padding: 1rem 0 0 0;
      }
    `}
`;

export const OpenContent = styled.div<{ open?: boolean }>`
  max-height: 0;
  height: 0;
  transition: height 0.3s, opacity 0.3s, padding 0.3s;
`;

export const ClosedContent = styled.div<{ open?: boolean }>`
  transition: height 0.3s, opacity 0.3s, padding 0.3s;

  :hover {
    cursor: pointer;
  }
`;

export const ArrowIcon = styled(SelectArrow)`
  height: 0.75rem;
  padding-right: 0.5rem;
  transition: transform 0.2s;
  fill: ${(props) => props.theme.globalColors.solidPurple};
  transition: fill 200ms;
`;
