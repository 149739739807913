import { useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { interfaceSortingDirection, interfaceEditPortfolio, interfaceFocus } from 'app/global/recoil/atoms';
import { useTranslation } from 'app/global/hooks';
import { SortingDirectionType } from 'app/global/constants/sortingDirectionEnums';
import { Segment } from 'components/atoms';
import { UserCoinSortSelect } from 'components/organisms';
import { StyledCoinListHeader, Left, Right, SegmentControllerHolding, ButtonEdit } from './styles';

export const CoinListHeader: React.FC = () => {
  // Translation
  const { t } = useTranslation();

  // Shared state management
  const [interfaceSortingDirectionState, setInterfaceSortingDirectionState] = useRecoilState(interfaceSortingDirection);
  const [interfaceFocusState, setInterfaceFocusState] = useRecoilState(interfaceFocus);
  const setInterfaceEditPorfolioValue = useSetRecoilState(interfaceEditPortfolio);

  // Handle Interaction - Sorting Direction
  const handleInteractionHolding = useCallback(
    (direction: SortingDirectionType) => {
      if (direction === SortingDirectionType.Ascending) {
        setInterfaceSortingDirectionState(SortingDirectionType.Descending);
      }

      if (direction === SortingDirectionType.Descending) {
        setInterfaceSortingDirectionState(SortingDirectionType.Ascending);
      }
    },
    [setInterfaceSortingDirectionState],
  );

  const handleInteractionEdit = (newState: boolean) => {
    setInterfaceFocusState(newState);
    setInterfaceEditPorfolioValue(newState);
  };

  return (
    <StyledCoinListHeader>
      <Left>
        <UserCoinSortSelect />
        <SegmentControllerHolding>
          <Segment
            activeIndex={interfaceSortingDirectionState}
            index={interfaceSortingDirectionState}
            onInteraction={() => handleInteractionHolding(interfaceSortingDirectionState)}
          >
            {interfaceSortingDirectionState === SortingDirectionType.Descending ? (
              <ReactSVG src="icons/descending.svg" />
            ) : (
              <ReactSVG src="icons/ascending.svg" />
            )}
          </Segment>
        </SegmentControllerHolding>
      </Left>
      <Right>
        <ButtonEdit onClickHandler={() => handleInteractionEdit(!interfaceFocusState)} title={t('edit')} />
      </Right>
    </StyledCoinListHeader>
  );
};
