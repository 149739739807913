import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  mode: 'light',
  fontFamily: {
    sansBold: ['Centra-No2-Bold'],
    sansRegular: ['Centra-No2-Regular'],
    sansThin: ['Centra-No2-Thin'],
    serifBook: ['Galaxie-Copernicus-Book'],
    serifBookItalic: ['Galaxie-Copernicus-BookItalic'],
  },
  fontSize: {
    textExtraSmall: '0.75rem',
    textSmall: '0.875rem',
    textBase: '1rem',
  },
  fontWeight: {
    fontThin: 100,
    fontExtraLight: 200,
    fontLight: 300,
    fontNormal: 400,
    fontMedium: 500,
    fontSemiBold: 600,
    fontBold: 700,
    fontExtraBold: 800,
    fontBlack: 900,
  },
  letterSpacing: {
    trackingTighter: '-0.05em',
    trackingTight: '-0.025em',
    trackingNormal: '0em',
    trackingWide: '0.025em',
    trackingWider: '0.05em',
    trackingWidest: '0.1em',
  },
  lineHeight: {
    leading3: '0.75rem',
    leading4: '1rem',
    leading5: '1.25rem',
    leading6: '1.5rem',
    leading7: '1.75rem',
    leading8: '2rem',
    leading9: '2.25rem',
    leading10: '2.5rem',
    leadingNone: '1rem',
    leadingTight: '1.25rem',
    leadingSnug: '1.375rem',
    leadingNormal: '1.5rem',
    leadingRelaxed: '1.625rem',
    leadingLoose: '2rem',
  },
  zIndex: {
    z0: 0,
    z10: 10,
    z20: 20,
    z30: 30,
    z40: 40,
    z50: 50,
    z60: 60,
    z70: 70,
    z80: 80,
    z90: 90,
    z100: 100,
  },
  opacity: {
    opacity0: 0,
    opacity5: 0.05,
    opacity10: 0.1,
    opacity20: 0.2,
    opacity25: 0.25,
    opacity30: 0.3,
    opacity40: 0.4,
    opacity50: 0.5,
    opacity60: 0.6,
    opacity70: 0.7,
    opacity75: 0.75,
    opacity80: 0.8,
    opacity90: 0.9,
    opacity95: 0.95,
    opacity100: 1,
  },
  padding: {
    p0: '0',
    p1: '0.25rem',
    p2: '0.5rem',
    p3: '0.75rem',
    p4: '1rem',
  },
  margin: {
    m0: '0',
    m1: '0.25rem',
    m2: '0.5rem',
    m3: '0.75rem',
    m4: '1rem',
  },
  space: {
    s0: '0',
    s1: '0.25rem',
    s2: '0.5rem',
    s3: '0.75rem',
    s4: '1rem',
  },
  borderRadius: {
    roundedNone: '0px',
    roundedSm: '0.125rem',
    rounded: '0.25rem',
    roundedMd: '0.375rem',
    roundedLg: '0.5rem',
    roundedXl: '0.75rem',
    rounded2Xl: '1rem',
    rounded3Xl: '1.5rem',
    roundedFull: '9999px',
  },
  breakpoints: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  },
  globalColors: {
    // TODO: BACKUP COLORS
    // light: 'hsla(0, 0%, 100%, 1)',
    // trans: 'hsla(0, 0%, 100%, 0)',
    // dark: '	hsl(218, 15%, 22%)',
    // transWhite: 'rgba(255, 255, 255, 0.16)',
    // transGrey: 'rgba(255, 255, 255, 0.08)',
    // deepPurple: 'hsl(235, 41%, 17%)',
    // deeperPurple: 'hsl(227, 77%, 10%)',
    // transPurple: 'hsla(252, 71%, 43%, 0.2)',
    // blurryPurple: 'rgba(27, 34, 64, 0.8)',
    // purple: 'hsl(252, 71%, 43%)',
    // solidPurple: 'rgb(173, 155, 246)',
    // shallowPurple: 'hsl(252, 63%, 59%)',
    // fadedPurple: 'hsla(242, 20%, 35%, 1)',
    // solidGreen: 'rgb(119, 217, 155)',
    // fadedGreen: 'rgba(119, 217, 155, 0.2)',
    // transGreen: 'rgba(154, 230, 180, 0.16)',
    // solidPink: 'rgb(252, 132, 132)',
    // fadedPink: 'rgba(252, 132, 132, 0.2)',
    light: 'hsla(218, 15%, 22%, 1)',
    trans: 'hsla(0, 0%, 100%, 0)',
    dark: '	hsla(0, 0%, 100%, 1)',
    transWhite: 'rgba(255, 255, 255, 0.16)',
    transGrey: 'rgba(255, 255, 255, 0.08)',
    deepPurple: 'hsla(218, 15%, 22%, 0.1)',
    deeperPurple: 'hsla(0, 0%, 100%, 1)',
    transPurple: 'hsla(252, 71%, 43%, 0.2)',
    blurryPurple: 'rgba(27, 34, 64, 0.1)',
    purple: '#1EE7FF',
    solidPurple: '#F8667B',
    shallowPurple: 'hsl(252, 63%, 59%)',
    fadedPurple: 'hsla(242, 20%, 35%, 0.3)',
    solidGreen: '#51BABB',
    fadedGreen: 'rgba(119, 217, 155, 0.2)',
    transGreen: 'rgba(154, 230, 180, 0.16)',
    solidPink: '#E7368A',
    fadedPink: 'rgba(252, 132, 132, 0.2)',
  },
  shadows: {
    header: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    basket: '0px -4px 4px 0px rgba(0, 0, 0, 0.05)',
    card: '0px 4px 4px 0px rgba(0,0,0,0.1)',
    small: '0px 2px 2px 0px rgba(0,0,0,0.1)',
  },
};
