import styled from 'styled-components';

export const StyledCoinListCointainerSkeleton = styled.div`
  overflow: hidden;
`;

export const SkeletonPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 8.75rem;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all 300ms;
`;
