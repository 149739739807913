import styled from 'styled-components';
import { SegmentController } from 'components/atoms';

export const StyledGraphs = styled.div`
  position: relative;
`;

/* Overwriting styles */

export const SegmentControllerChartPeriod = styled(SegmentController)`
  z-index: ${(props) => props.theme.zIndex.z80};
  position: absolute;
  top: 0.25rem;
  right: 0;
  width: 28rem;
`;
