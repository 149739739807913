import useSound from 'use-sound';
import soundEffect from 'assets/sounds/click.wav';
import { Container, Picture, PictureLoading } from './styles';

export const Profile: React.FC<ProfileProps> = ({
  picture,
  loading,
  onInteractionHandler,
  size,
  description,
  balance,
}) => {
  // Initialize - sound effect
  const [playSoundEffect] = useSound(soundEffect);
  const handleInteraction = () => {
    playSoundEffect();
    onInteractionHandler();
  };

  const balanceReduced = Math.sign(balance || 0);

  if (loading) {
    return (
      <Container size={size} balance={balanceReduced}>
        <PictureLoading />
      </Container>
    );
  }

  if (picture) {
    return (
      <Container onClick={() => handleInteraction()} size={size} balance={balanceReduced}>
        <Picture src={picture} alt={description || ''} />
      </Container>
    );
  }

  return (
    <Container size={size} balance={balanceReduced}>
      <PictureLoading />
    </Container>
  );
};

interface ProfileProps {
  loading?: boolean;
  picture: string | undefined | null;
  description?: string;
  size: ProfileSizeType;
  balance?: number | undefined | null;
  onInteractionHandler: () => void;
}

export enum ProfileSizeType {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}
