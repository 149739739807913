export const QueryResult: React.FC<QueryResultProps> = ({ loading, loadingComponent, error, data, children }) => {
  if (error) {
    return <p>ERROR: {error.message}</p>;
  }
  if (loading) {
    return <>{loadingComponent || 'loading'}</>;
  }
  if (!data) {
    return <p>No data.</p>;
  }
  if (data) {
    return children;
  }
};

interface QueryResultProps {
  loading: boolean;
  loadingComponent?: React.ReactNode | React.ReactNode[];
  error: any;
  data: any;
  children: any;
}
