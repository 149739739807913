import { darkTheme } from './darkTheme';

export const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;

export const media = {
  custom: customMediaQuery,
  sm: customMediaQuery(darkTheme.breakpoints.sm),
  md: customMediaQuery(darkTheme.breakpoints.md),
  lg: customMediaQuery(darkTheme.breakpoints.lg),
  xl: customMediaQuery(darkTheme.breakpoints.xl),
  xxl: customMediaQuery(darkTheme.breakpoints.xxl),
};
