import { useQuery } from '@apollo/client';
import { GetCoinBasicsQuery, GetCoinBasicsQueryVariables } from 'generated/graphql';
import { GET_COIN_BASICS } from 'app/global/graphql/getCoinBasics';

export const useCoinBasics = (id: string) => {
  const { loading, error, data } = useQuery<GetCoinBasicsQuery, GetCoinBasicsQueryVariables>(GET_COIN_BASICS, {
    variables: { getCoinId: id },
  });

  return {
    loading,
    error,
    data,
  };
};
