import { PingData, SupportedVsCurrenciesData, CoinData, CoinsMarketData } from './CoinGeckoData';
import { Wrapper } from './styles';

export const Endpoints: React.FC = () => {
  return (
    <Wrapper>
      <PingData />
      <CoinsMarketData id={'solana'} />
      <CoinData id={'solana'} />
      <SupportedVsCurrenciesData />
    </Wrapper>
  );
};
