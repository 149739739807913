import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userHoldingsTotal, settingsUserCurrency, settingsPostiveVibesOnly } from 'app/global/recoil/atoms';
import { usePrevious, useTranslation } from 'app/global/hooks';
import useSound from 'use-sound';
import { SelectPortfolio } from 'app/global/utils/selectPortfolio';
import soundEffectProfit from 'assets/sounds/coin-profit.wav';
import soundEffectLoss from 'assets/sounds/coin-loss.wav';
import { calculateTotalHoldings } from 'app/global/utils/calculateTotalHoldings';
import { notifyIncrease, notifyDecrease } from 'app/global/utils/toasts';
import { useCoinsMarket } from 'app/global/graphql/hooks/useCoinsMarket';
import { Notification } from 'components/atoms';
import { QueryResult } from 'components/molecules';
import { StyledButtonPortfolio, Header, Footer, Amount, Currency } from './styles';

export const PortfolioTotal: React.FC = () => {
  // Translation
  const { t } = useTranslation();

  // Select Portfolio
  const portfolio = SelectPortfolio();

  // Shared state management
  const [userHoldingsTotalState, setUserHoldingsTotalState] = useRecoilState(userHoldingsTotal);
  const settingsUserCurrencyState = useRecoilValue(settingsUserCurrency);
  const settingsPostiveVibesOnlyState = useRecoilValue(settingsPostiveVibesOnly);

  // Convert - Shared state coin list to a comma separated format
  const coinsAsString = Array.prototype.map.call(portfolio, (coin) => coin.id).toString();

  // Apollo custom hook - Coins market information
  const { loading, error, data } = useCoinsMarket(settingsUserCurrencyState, coinsAsString, 60000);

  // Calculate - Total holdings
  const totalValue = calculateTotalHoldings(portfolio, data);

  // Check for previous value
  const previousTotalValue = usePrevious(totalValue);

  // Initialize - sound effects
  const [playSoundEffectIncrease] = useSound(soundEffectProfit, { volume: 0.5 });
  const [playSoundEffectDecrease] = useSound(soundEffectLoss, { volume: 0.1 });

  // Notifications - Logic based on price difference
  if (previousTotalValue !== undefined && totalValue - previousTotalValue !== 0) {
    const priceDifference = totalValue - previousTotalValue;
    const balance = Math.sign(priceDifference);

    if (balance === 1) {
      notifyIncrease(<Notification label="Portfolio Increased" value={`$${priceDifference.toFixed(2)}`} />);
      playSoundEffectIncrease();
    }

    if (balance === -1 && !settingsPostiveVibesOnlyState) {
      notifyDecrease(<Notification label="Portfolio Decreased" value={`$${priceDifference.toFixed(2)}`} />);
      playSoundEffectDecrease();
    }
  }

  // Update - Shared State
  useEffect(() => {
    setUserHoldingsTotalState(totalValue);
  }, [setUserHoldingsTotalState, totalValue]);

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <StyledButtonPortfolio
        onClick={() => {
          playSoundEffectIncrease();
          notifyIncrease(<Notification label="Im rich bitch! 🎉" value="" />);
        }}
      >
        <Header>{t('total-portfolio')}</Header>
        <Amount>
          <Currency>{settingsUserCurrencyState}&nbsp;</Currency> {userHoldingsTotalState.toFixed(2)}
        </Amount>
        <Footer>
          {portfolio.length} {t('items')}
        </Footer>
      </StyledButtonPortfolio>
    </QueryResult>
  );
};
