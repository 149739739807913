import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';
import { find } from 'lodash';
import { interfaceFocus } from 'app/global/recoil/atoms';
import { useUrlQuery, useTranslation, useWindowCheck } from 'app/global/hooks';
import { UserSettings, UserProfile, UserLanguageSelect } from 'components/organisms';
import { ArrowLeft } from 'assets/icons';
import { SelectPortfolio } from 'app/global/utils/selectPortfolio';
import { StyledHeader, Wrapper, Start, End, Heading, Navigation, ButtonLogin, Logo } from './styles';

export const Header: React.FC = () => {
  // Window check
  const windowWidth = useWindowCheck();

  // Translation
  const { t } = useTranslation();

  // Authentication
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // Select Portfolio
  const portfolio = SelectPortfolio();

  // Shared State
  const interfaceFocusState = useRecoilValue(interfaceFocus);

  // Get URL query - name
  const urlQueryName = useUrlQuery().get('name');

  // Find - Correct coin data
  const coin = find(portfolio, ['id', urlQueryName]);

  return (
    <StyledHeader isZonedOut={interfaceFocusState}>
      <Wrapper>
        <Start>
          {isAuthenticated && <UserProfile isLoading={isLoading} user={user} />}
          <Link to={`/`}>
            {urlQueryName === null ? (
              <Navigation>
                <Logo />
              </Navigation>
            ) : (
              <Navigation>
                <ArrowLeft />
                <Heading title={coin?.name} />
              </Navigation>
            )}
          </Link>
        </Start>
        <End>
          {windowWidth > 1280 && <UserLanguageSelect type="header" languages={['nl', 'en', 'pt']} />}
          <UserSettings />
          {!isAuthenticated && <ButtonLogin onClickHandler={() => loginWithRedirect()} title={t('login')} />}
        </End>
      </Wrapper>
    </StyledHeader>
  );
};
