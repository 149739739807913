import styled from 'styled-components';
import { Tag } from 'components/atoms';

export const Value = styled.span`
  max-width: calc(100vw - 8rem);
  color: ${({ theme }) => theme.globalColors.light};
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/* Overwriting styles */
export const StyledTag = styled(Tag)<{ balance?: number }>`
  text-transform: uppercase;
  margin-top: 0.75rem;
  background-color: ${(props) => props.balance === 1 && props.theme.globalColors.solidGreen};
  background-color: ${(props) => props.balance === -1 && props.theme.globalColors.solidPink};
`;
