import { Signifier, SignifierFlavorType } from 'components/atoms';
import { RangeContainer } from '../';
import { BarWeeklyStart, BarDailyStart, BarCurrent, BarDailyEnd, BarWeeklyEnd } from './styles';

export const RangeWeekly: React.FC<RangeWeeklyProps> = ({
  label,
  weeklyLow,
  weeklyHigh,
  dailyLow,
  dailyHigh,
  current,
}) => {
  if (weeklyHigh && weeklyLow && dailyLow && dailyHigh && current) {
    const total = weeklyHigh - weeklyLow;
    const weeklyStart = dailyLow - weeklyLow;
    const dailyStart = current - dailyLow;
    const dailyEnd = dailyHigh - current;
    const weeklyEnd = weeklyHigh - dailyHigh;

    const weeklyStartPercentage = (weeklyStart * 100) / total;
    const dailyStartPercentage = (dailyStart * 100) / total;
    const dailyEndPercentage = (dailyEnd * 100) / total;
    const weeklyEndPercentage = (weeklyEnd * 100) / total;

    return (
      <RangeContainer
        label={label}
        signifiers={
          <>
            <Signifier label="current price" flavor={SignifierFlavorType.PinPoint} />
            <Signifier label="last 24 hours" flavor={SignifierFlavorType.SubRange} />
            <Signifier label="last 7 days" flavor={SignifierFlavorType.FullRange} />
          </>
        }
      >
        <BarWeeklyStart percentage={weeklyStartPercentage} />
        <BarDailyStart percentage={dailyStartPercentage} />
        <BarCurrent />
        <BarDailyEnd percentage={dailyEndPercentage} />
        <BarWeeklyEnd percentage={weeklyEndPercentage} />
      </RangeContainer>
    );
  }

  return <></>;
};

interface RangeWeeklyProps {
  label: string;
  weeklyLow: number | undefined | null;
  weeklyHigh: number | undefined | null;
  dailyLow: number | undefined | null;
  dailyHigh: number | undefined | null;
  current: number | undefined | null;
}
