import styled from 'styled-components';
import { media } from 'app/global/styles/mediaViewport';
import { SegmentController, Button } from 'components/atoms';

export const StyledCoinListHeader = styled.header`
  z-index: ${(props) => props.theme.zIndex.z80};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${(props) => props.theme.globalColors.deeperPurple};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
`;

/* Overwriting styles */

export const SegmentControllerHolding = styled(SegmentController)`
  width: 4rem;
  min-width: 4rem;
  margin-left: 1rem;
`;

export const ButtonEdit = styled(Button)`
  margin-left: 1rem;
  width: 6rem;

  /* Responsive - overwrites */

  ${media.xl} {
    width: 8rem;
  }
`;
