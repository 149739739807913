import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-family: 'Centra-No2-Regular';
  text-transform: uppercase;
  color: ${(props) => props.theme.globalColors.light};
  font-size: ${(props) => props.theme.fontSize.textExtraSmall};
  line-height: 1rem;
  letter-spacing: 0.1em;
  user-select: none;
`;

export const Description = styled.p`
  margin: 0 0.6rem 0 0;
`;

export const InputWrapper = styled.div<{ isChecked?: boolean }>`
  background-color: ${({ isChecked, theme }) =>
    isChecked ? theme.globalColors.solidGreen : theme.globalColors.fadedPurple};
  height: 0.75rem;
  width: 2.5rem;
  border-radius: 10px;
  position: relative;

  transition: background-color 0.2s 0.1s;
`;

export const Slider = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 99px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: absolute;
  top: -0.25rem;
  left: 0;
  cursor: pointer;

  transition: transform 0.2s, background-color 0.2s;
`;

export const Input = styled.input`
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + ${Slider} {
    transform: translateX(1.25rem);
  }
`;
