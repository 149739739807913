import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputWrapper = styled.section`
  position: relative;
  flex: 1 1 0%;
  max-height: 20rem;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
  border-radius: 1rem;
  padding: 2rem;
`;

export const OutputWrapper = styled.section`
  position: relative;
  flex: 1 1 0%;
  max-height: 20rem;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.globalColors.fadedPurple};
  border-radius: 1rem;
  padding: 2rem;
`;

export const InputList = styled.div`
  border-left-width: 2px;
  border-style: solid;
  border-color: ${(props) => props.theme.globalColors.solidGreen};
`;

export const CheckboxContainer = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

export const ButtonGroup = styled.div`
  position: absolute;
  bottom: 0;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;
