import { StyledSignifier, SignifierLabel, SignifierIndicator, SignifierIndicatorWrapper } from './styles';

export const Signifier: React.FC<SignifierProps> = ({ label, flavor }) => {
  return (
    <StyledSignifier>
      <SignifierIndicatorWrapper>
        <SignifierIndicator flavor={flavor} />
      </SignifierIndicatorWrapper>
      <SignifierLabel title={label || '-'} />
    </StyledSignifier>
  );
};

interface SignifierProps {
  label: string | undefined;
  flavor: SignifierFlavorType;
}

export enum SignifierFlavorType {
  PinPoint = 'PIN_POINT',
  FullRange = 'FULL_RANGE',
  SubRange = 'SUB_RANGE',
  Highlight = 'HIGHLIGHT',
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
}
