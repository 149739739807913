import { useQuery } from '@apollo/client';
import { QueryResult, CodePreview } from 'components/molecules';
import { GET_COIN_BASICS } from 'app/global/graphql/getCoinBasics';
import { GetCoinBasicsQuery, GetCoinBasicsQueryVariables } from 'generated/graphql';

interface CoinDataProps {
  id: string;
}

export const CoinData: React.FC<CoinDataProps> = ({ id }) => {
  const { loading, error, data } = useQuery<GetCoinBasicsQuery, GetCoinBasicsQueryVariables>(GET_COIN_BASICS, {
    variables: { getCoinId: id },
  });

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <CodePreview title={'Coin data'} data={data} />
    </QueryResult>
  );
};
