import { ApolloError } from '@apollo/client';
import { useCallback, useState } from 'react';
import { Coin } from 'generated/graphql';
import { CoinSentiment } from 'components/organisms';
import { QueryResult, DataValueGroup, ExpansionPanel } from 'components/molecules';
import { DataValue, DateValueType, DataDisplay, DateDisplayType } from 'components/atoms';
import { StyledDataDisplayGroup } from './styles';

export const SectionSocial: React.FC<SectionSocialProps> = ({ loading, error, data }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <QueryResult error={error} loading={loading} data={data}>
      <DataValueGroup title="Social sentiment">
        <CoinSentiment
          positive={data?.sentiment_votes_up_percentage}
          negative={data?.sentiment_votes_down_percentage}
        />
      </DataValueGroup>
      <DataValueGroup title="Resources">
        <StyledDataDisplayGroup>
          <DataDisplay label="Contract address" value={data?.contract_address} dataType={DateDisplayType.CopyText} />
        </StyledDataDisplayGroup>
        <StyledDataDisplayGroup>
          <DataDisplay label="Homepage" value={data?.links.homepage[0]} dataType={DateDisplayType.ExternalLink} />
        </StyledDataDisplayGroup>
        <StyledDataDisplayGroup>
          <DataDisplay
            label="Twitter handle"
            value={`@${data?.links.twitter_screen_name}`}
            dataType={DateDisplayType.ExternalLink}
          />
        </StyledDataDisplayGroup>
        <StyledDataDisplayGroup>
          <DataDisplay label="Twitter hashtag" value={`#${data?.id}`} dataType={DateDisplayType.ExternalLink} />
        </StyledDataDisplayGroup>
        <StyledDataDisplayGroup>
          <DataDisplay
            label="Telegram channel identifier"
            value={data?.links.telegram_channel_identifier}
            dataType={DateDisplayType.ExternalLink}
          />
        </StyledDataDisplayGroup>
        <StyledDataDisplayGroup>
          <ExpansionPanel onClick={handleToggle} title="show more resource links" active={isActive} initialClosed>
            <DataValueGroup title="Links">
              {data?.links.homepage.map((homepage, i) => (
                <DataValue
                  label="Homepage"
                  value={homepage}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'homepage' + i}
                />
              ))}
              {data?.links.blockchain_site?.map((blockchainSite, i) => (
                <DataValue
                  label="Blockchain site"
                  value={blockchainSite}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'blockchainSite' + i}
                />
              ))}
              {data?.links.official_forum_url.map((officialForumUrl, i) => (
                <DataValue
                  label="Official forum url"
                  value={officialForumUrl}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'officialForumUrl' + i}
                />
              ))}
              {data?.links.chat_url.map((chatUrl, i) => (
                <DataValue
                  label="Official forum url"
                  value={chatUrl}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'chatUrl' + i}
                />
              ))}
              {data?.links.announcement_url.map((announcementUrl, i) => (
                <DataValue
                  label="Announcement url"
                  value={announcementUrl}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'announcementUrl' + i}
                />
              ))}
            </DataValueGroup>
            <DataValueGroup title="Social Media">
              <DataValue
                label="Facebook username"
                value={data?.links.facebook_username}
                dataType={DateValueType.ExternalLink}
              />
              <DataValue
                label="Telegram channel identifier"
                value={data?.links.telegram_channel_identifier}
                dataType={DateValueType.ExternalLink}
              />
              <DataValue
                label="Subreddit url"
                value={data?.links.subreddit_url}
                dataType={DateValueType.ExternalLink}
              />
            </DataValueGroup>
            <DataValueGroup title="Repositories">
              {data?.links.repos_url.github.map((github, i) => (
                <DataValue
                  label="Github"
                  value={github}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'github' + i}
                />
              ))}
              {data?.links.repos_url.bitbucket.map((bitbucket, i) => (
                <DataValue
                  label="Bitbucket"
                  value={bitbucket}
                  dataType={DateValueType.ExternalLink}
                  key={data.id + 'bitbucket' + i}
                />
              ))}
            </DataValueGroup>
          </ExpansionPanel>
        </StyledDataDisplayGroup>
      </DataValueGroup>
    </QueryResult>
  );
};

interface SectionSocialProps {
  data: Coin | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}
