import styled from 'styled-components';
import { media } from 'app/global/styles/mediaViewport';

export const StyledDataValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  &:nth-child(even) {
    background-color: ${(props) => props.theme.globalColors.deepPurple};
  }

  ${media.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Label = styled.span`
  display: block;
  min-width: 16rem;
  font-family: 'Centra-No2-Regular';
  margin-left: 1rem;
  color: ${(props) => props.theme.globalColors.fadedPurple};
  user-select: none;
  line-height: 2.5rem;
`;

export const Value = styled.span`
  display: block;
  font-family: 'Centra-No2-Thin';
  font-size: 0.875rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${(props) => props.theme.globalColors.deepPurple};
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
`;

export const LinkValue = styled.a`
  display: block;
  font-family: 'Centra-No2-Thin';
  font-size: 0.875rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${(props) => props.theme.globalColors.solidPurple};
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-overflow: ellipsis;
`;
