import { toast, Zoom } from 'react-toastify';

// Notifications - Custom id's for avoiding duplicates
const notificationIncreaseId = `notification-increase-id-${Date.now()}`;
const notificationDecreaseId = `notification-decrease-id-${Date.now()}`;

// Notifications - Initializing different costum types
export const notifyIncrease = (value: string | React.ReactNode) => {
  toast(value, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: 'notification-increase',
    toastId: notificationIncreaseId,
    pauseOnFocusLoss: false,
    closeButton: false,
    transition: Zoom,
    autoClose: 3000,
  });
};

export const notifyDecrease = (value: string | React.ReactNode) => {
  toast(value, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: 'notification-decrease',
    toastId: notificationDecreaseId,
    pauseOnFocusLoss: false,
    closeButton: false,
    transition: Zoom,
    autoClose: 3000,
  });
};

export const notifySettingChange = (value: string | React.ReactNode) => {
  toast(value, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: 'notification-setting-change',
    pauseOnFocusLoss: false,
    closeButton: false,
    transition: Zoom,
    autoClose: 3000,
  });
};
