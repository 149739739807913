import styled from 'styled-components';

export const StyledSegmentController = styled.div`
  display: flex;
  height: 2.5rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
  border-radius: 0.25rem;
  transition: border-color 300ms;
`;
