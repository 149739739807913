import { memo } from 'react';
import { StyledHeadingSmall } from './styles';

const HeadingSmall: React.FC<HeadingSmallProps> = ({ title, className }) => {
  return <StyledHeadingSmall className={className}>{title}</StyledHeadingSmall>;
};

interface HeadingSmallProps {
  title: string | undefined;
  className?: string;
}

export const HeadingSmallMemoized = memo(HeadingSmall);
