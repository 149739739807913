import { useQuery } from '@apollo/client';
import { FindPortfolioByIdQuery, FindPortfolioByIdQueryVariables } from 'generated/graphql';
import { FIND_PORTFOLIO_BY_ID } from 'app/global/graphql/findPortfolioByID';
import { useAuthTokenContext } from 'app/global/context/AuthTokenContext';

export const useFindPortfolioByID = (id: string) => {
  const { getToken } = useAuthTokenContext();

  const { loading, error, data } = useQuery<FindPortfolioByIdQuery, FindPortfolioByIdQueryVariables>(
    FIND_PORTFOLIO_BY_ID,
    {
      variables: {
        id: id,
      },
      context: { clientName: 'fauna', headers: { authorization: `Bearer ${getToken()}` } },
    },
  );

  return {
    loading,
    error,
    data,
  };
};
