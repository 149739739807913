import styled from 'styled-components';
import { HeadingSmall } from 'components/atoms';

export const StyledDataDisplayWrapper = styled.div`
  flex: 1 1 0;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  background-color: ${(props) => props.theme.globalColors.deepPurple};
`;

/* Overwriting styles */
export const Label = styled(HeadingSmall)`
  color: ${(props) => props.theme.globalColors.solidPurple};
  line-height: 1.75rem;
  margin-bottom: 0;
  padding: 0.5rem;
  text-align: center;
`;
